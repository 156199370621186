import React, { useEffect } from 'react'
import CircuitDesigner from '../../../../components/circuit-designer'
import CircuitToCodeGenerator from '../../../../components/circuit-to-code-generator'
import { BackendConfigs } from '../../../../config.environment'
import { ProjectDataType } from '../../../../types/Dashboard'
import { motion } from 'framer-motion'
import { framerAnimation } from '../../../../theme/animation/MotionConfigs'

type Props = {
  circuitJSON: string
  setCircuitJSON: React.Dispatch<React.SetStateAction<string>>
  project: ProjectDataType
}

function CircuitDesignerComponent({
  circuitJSON,
  setCircuitJSON,
  project,
}: Props) {

  async function saveCircuit(circuitJSON: string) {
    try {
      const response = await fetch(`${BackendConfigs.url}/SaveCircuitAPI`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          circuitJSON
        })
      })
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    // console.log(project.circuit.circuitJSON);
    // console.log(circuitJSON);
  },[])

  return (
    <motion.div
      initial={framerAnimation.left2right.initial}
      animate={framerAnimation.left2right.animate}
      exit={framerAnimation.left2right.exit}
      transition={framerAnimation.left2right.transition}
    >
      <div style={{
        height: "90vh",
        // border: "1px solid gray"
      }}>
        <CircuitToCodeGenerator circuitJSON={circuitJSON} setCircuitJSON={setCircuitJSON} />
      </div>
    </motion.div>
  )
}

export default CircuitDesignerComponent