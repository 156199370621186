import React from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { Card, CardActions, Typography } from '@mui/material';
import { eduCOBOTColorScheme } from '../../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';

type Props = {}

function SampleProjectListComponent({ }: Props) {
    const navigate = useNavigate()
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "start",
                flexWrap: "wrap"
            }}>
                <Typography variant="h5" sx={{
                    width: "100%",
                    margin: "30px 0",
                    textAlign: "center"
                }}>Activities</Typography>
                <ProjectCard title='LED Light Control' image={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/blueprints/LED.png"} />
                <ProjectCard title='Buzzer Control' image={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/blueprints/buzzer.png"} />
                <ProjectCard title='LCD Display' image={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/blueprints/LCD.png"} />
                <ProjectCard title='Distance Monitoring' image={"https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/blueprints/ultrasonic.png"} />
                <Typography variant="h5" sx={{
                    width: "100%",
                    margin: "30px 0",
                    textAlign: "center"
                }}
                >Projects</Typography>
                <ProjectCard title='Door Lock And Unlocking System' image={"https://i0.wp.com/srituhobby.com/wp-content/uploads/2021/06/170.jpg?fit=1280%2C720&ssl=1"} />
                <ProjectCard title='IOT Weather Station' image={"https://i0.wp.com/srituhobby.com/wp-content/uploads/2021/06/170.jpg?fit=1280%2C720&ssl=1"} />
                <ProjectCard title='Smart Garbage Monitoring' image={"https://content.instructables.com/FMJ/IGOK/J48PV9RJ/FMJIGOKJ48PV9RJ.jpg?auto=webp&fit=bounds&frame=1&height=1024&width=1024"} />
                <ProjectCard title='Smart Door Bell' image={"https://i.ytimg.com/vi/wVdMKTvwRtQ/sddefault.jpg"} />
            </div>
        </div>
    )
}



function ProjectCard({ image, title }: { image: string, title: string }) {
    const navigate = useNavigate()
    return (

        <Card style={{
            width: "min(300px, 100%)",
            height: "min(200px, 500px)",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            borderRadius: "20px",
            margin: "10px",
            background: "transparent",
            backdropFilter: "blur(2px)",
            paddingBottom: "50px",
        }}
            sx={{
                ":hover": {
                    scale: "1.2",
                }
            }}
        >

            <img src={image} alt="" style={{
                // minWidth: "150px",
                width: "100%",
                maxWidth: "300px",
                height: "200px",
            }} />
            <CardActions style={{
                width: "100%",
                background: "#DDDDDD",
                textAlign: "center",
                position: "absolute",
                bottom: "0px",
                padding: "15px",
            }}
                onClick={() => {
                    // navigate(routes.DASHBOARD.PROJECT.ROOT + "/lhfbahkbfkhjasd")
                }}
            >
                <h5 style={{
                    width: "100%",
                    color: eduCOBOTColorScheme.gray,
                    textAlign: "center",
                }} >
                    {title}
                </h5>
            </CardActions>
        </Card>
    )
}

export default SampleProjectListComponent