import React from 'react'

type Props = {}

function MonitoringFooter({}: Props) {
  return (
    <div style={{
        height:"0dvh",
    }}>
      
    </div>

  )
}

export default MonitoringFooter