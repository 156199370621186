import { Button, TextField } from '@mui/material'
import React, { useContext, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import { toast } from 'react-toastify'
import { BackendConfigs } from '../../../config.environment'
import { APIResponse } from '../../../types/Response'
import { ContentDialogBox } from '../../../components/dialog_box'
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes'
import { SettingContext } from '../../../context/SettingsContext'

type Props = {
    setIsDataChanged: React.Dispatch<React.SetStateAction<any>>
}

function CreateProjectComponent({ setIsDataChanged }: Props) {
    const { user } = useContext(UserContext)
    const {settings, handleGlobalLoading} = useContext(SettingContext)
    const [projectName, setProjectName] = useState("")
    const [projectDescription, setProjectDescription] = useState("")
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false)
    const [isCreateSuccess, setIsCreateSuccess] = useState(false)
    const navigate = useNavigate()


    async function createProject() {
        handleGlobalLoading(true, "Creating Project : "+projectName)
        if (!projectName) {
            toast.error("Project Name is required")
            return
        }
        if (!projectDescription) {
            toast.error("Project Description is required")
            return
        }
        try {
            const response = await fetch(`${BackendConfigs.url}/CreateProjectAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    name: projectName,
                    description: projectDescription,
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success(result.message)
                    setIsCreateSuccess(true)
                    const projectId = result.data.project._id
                    setTimeout(() => {
                        navigate(routes.DASHBOARD.PROJECT.ROOT + "/" + projectId)
                    },3000)

                } else {
                    toast.error(result.message)
                }
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }



    return (
        <div style={{
            // minHeight: "30vh",
            display: 'flex',
            margin: "0 10px",
            alignItems: "center",
            flexDirection: "column",
            backdropFilter: "blur(5px)",
            borderRadius: "10px",
            padding: "10px",
        }}>
            <div style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                width: "100%",
            }}>
                <Button size='small' variant='contained' color={isCreateDialogOpen ? "warning" : 'primary'} style={{
                    textTransform: "none",
                    borderRadius: "6px",
                    fontWeight: "bolder",
                    // fontSize: "10px",
                    padding:"5px 30px"
                }} onClick={() => {
                    setIsCreateDialogOpen(!isCreateDialogOpen)
                }}>{isCreateDialogOpen ? "Close" : "Create Project"}</Button>
            </div>
            {
                isCreateDialogOpen &&
                <div style={{
                    padding: 10,
                    width: "100%"
                }}>
                    <TextField
                        fullWidth
                        label='Enter Project Name'
                        style={{
                            margin: 10
                        }}
                        value={projectName}
                        onChange={(e) => {
                            if (e.target.value.length > 20) {
                                alert("Length should not be grater than 20 characters")
                            } else {
                                setProjectName(e.target.value)
                            }
                        }}
                    />
                    <TextField
                        fullWidth
                        label='Enter Project Description'
                        style={{
                            margin: 10
                        }}
                        value={projectDescription}
                        onChange={(e) => {
                            setProjectDescription(e.target.value)
                        }}
                        multiline
                        rows={4}
                    />
                    <div style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end"
                    }}>
                        <Button variant="contained" color="success" sx={{
                            textTransform: "none",
                            borderRadius: "6px",
                            fontWeight: "bolder",
                            // fontSize: "10px",
                            padding:"5px 50px"
                        }} size='small' onClick={() => {
                            createProject()
                        }}>
                            Create
                        </Button>
                    </div>
                </div>
            }
            <ContentDialogBox
                isOpen={isCreateSuccess}
                onClose={() => {
                    // setIsCreateSuccess(false)
                    // setIsCreateDialogOpen(false)
                    // setIsDataChanged(true)
                }}
                title={"Project Created Successfully"}
                content={<>
                    <div style={{

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column"
                    }}>
                        <img src="https://cdn-icons-gif.flaticon.com/10282/10282582.gif" width={"250px"} alt="" />
                        <h4>Wait, Opening project.....</h4>
                    </div>
                </>}
                maxWidth={"sm"}
            />
        </div>
    )
}

export default CreateProjectComponent