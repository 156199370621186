import React, { useContext, useEffect } from 'react'
import MicroPythonEditor from '../../../../components/micropython-block-editor'
import { ProjectDataType } from '../../../../types/Dashboard'
import CodeEditor from '../../../../components/code-editor'
import { motion } from 'framer-motion'
import { framerAnimation } from '../../../../theme/animation/MotionConfigs'
import Blockly from '../../../../components/micropython-block-editor/configs/micropython-blocks'
import { WorkspaceSvg } from 'blockly'
import { CircuitJSONType } from '../../../../types/Editor'
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext'
import { deployCode, getWiFi } from '../../../../components/micropython-block-editor/RoboticHandler'
import { Button, Card, Switch, Tooltip, Typography } from '@mui/material'
import MicroPythonToolBox from '../../../../components/micropython-block-editor/configs/micropython-toolbox';
import { addDynamicTextReturningBlocks } from '../../../../components/micropython-block-editor/configs/code-generator'
import { CircuitIDToBlocksMapper } from './CircuitToBlockMapper'

type Props = {
    xml: string
    setXml: (value: string) => void
    code: string
    setCode: (value: string) => void
    project: ProjectDataType
    setIsCircuitDesignerOpen: (value: boolean) => void
    circuitJSON: string,
    keys?: {
        [key: string]: string
    }
}


function CodeGenerator({
    code,
    setCode,
    xml,
    setXml,
    project,
    circuitJSON,
    keys
}: Props) {
    const [initialXML, setInitialXML] = React.useState<string>(xml)
    const [microPythonToolBox, setMicroPythonToolBox] = React.useState<typeof MicroPythonToolBox>(MicroPythonToolBox)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(false)
    const [isWiFi, setIsWiFi] = React.useState<boolean>(getWiFi())
    const [currentProcessMessage, setCurrentProcessMessage] = React.useState<string>("")
    const [console_messages, setConsole_messages] = React.useState<string[]>([])
    const [isRunning, setIsRunning] = React.useState<boolean>(false)
    useEffect(() => {
        localStorage.setItem("isWiFi", String(isWiFi))
    }, [isWiFi])
    async function getProcessMessages() {
        try {
            let response = await fetch("http://localhost:8080/process_message", { method: "POST" })
            let json = await response.json()
            setCurrentProcessMessage(json.message)
        } catch (error) {
            console.log(error);
        }
    }
    async function getConsoleMessages() {
        try {
            let response = await fetch("http://localhost:8080/console_outputs", { method: "POST" })
            let json = await response.json()
            // console.log(json.outputs);

            setConsole_messages(json.outputs.replace("[", "").replace("]", "").split(",").reverse())
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!!keys) {
            setMicroPythonToolBox(addDynamicTextReturningBlocks(
                Object.keys(keys).map((key) => {
                    return {
                        name: key,
                        value: keys[key]
                    }
                }) as { name: string; value: string }[],
                microPythonToolBox,
                "IOT-Resources",
                "red",
                "IOT"
            ))
        }
    }, [keys])

    useEffect(() => {
        if (!!project) {
            // if (project.project.variables.length > 0)
            
            setMicroPythonToolBox((microPythonToolBox)=>{
                return addDynamicTextReturningBlocks(
                    project.project.variables.map((variable) => {
                        return {
                            name: variable.name,
                            value: variable.name
                        }
                    }) as { name: string; value: string }[],
                    microPythonToolBox,
                    "IOT-Variables",
                    "red",
                    "IOT"
                )
            })
        }
    }, [project])

    useEffect(() => {
        const interval = setInterval(() => {
            // if (isRunning) {
                console.log("logging in console");
                getProcessMessages()
                getConsoleMessages()
            // }
        }, 1000)
        return () => {
            clearInterval(interval)
        }
    }, [])
    async function deployCodeOnMachine() {
        setIsRunning(true)
        handleGlobalLoading(true)
        await deployCode(code)
        handleGlobalLoading(false)
        setIsRunning(false)
    }
    return (
        <motion.div
            initial={framerAnimation.right2left.initial}
            animate={framerAnimation.right2left.animate}
            exit={framerAnimation.right2left.exit}
            transition={framerAnimation.right2left.transition}
        >
            <div
                style={{
                    height: "98dvh",
                    width: "100vw",
                    display: "flex",
                }}
            >
                <MicroPythonEditor
                    key={initialXML}
                    setCode={(code) => {
                        // console.log(code)
                        isBlockEditing && setCode(code)
                    }}
                    setXml={(xml) => {
                        // console.log(xml)
                        setXml(xml)
                    }}
                    initialXML={initialXML}
                />
                <div style={{
                    height: "93vh",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        height: "5vh",
                        zIndex: 999999999
                    }}>
                        <div>

                            <Tooltip title="Deploy">

                                <Button style={{
                                    border: "none",
                                }} onClick={() => {
                                    deployCodeOnMachine()
                                }}>
                                    <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                        width: "20px"
                                    }} alt="" />
                                </Button>
                            </Tooltip>
                            <Tooltip title={isBlockEditing ? "Code Editor" : "Block Editor"}>

                                <Button variant='outlined' onClick={() => {
                                    setIsBlockEditing(!isBlockEditing)
                                }} style={{
                                    border: "none",
                                    width: "50px"
                                }}>
                                    {
                                        isBlockEditing ?
                                            <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Block_Coding.png" height={20} alt="" />
                                            : <img src="https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/iot-assets/Syntax_Code.png" height={20} alt="" style={{
                                                filter: "invert(1)",
                                            }} />
                                    }
                                </Button>
                            </Tooltip>
                        </div>
                        <Typography sx={{
                            display: "flex",
                            alignItems: "center",
                        }}>
                            <h6>Cable</h6>
                            <Switch defaultChecked={isWiFi} onChange={() => setIsWiFi(!isWiFi)} checked={isWiFi} />
                            <h6>Wi-Fi</h6>
                        </Typography>


                    </div>
                    <div>

                    </div>
                    <CodeEditor code={code}
                        setCode={setCode} />
                </div>
                {
                    settings.globalLoading.loading && isRunning && <>

                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "50%",
                            position: "fixed",
                            bottom: "0",
                            right: "0",
                            zIndex: 9999999999999,
                        }}>
                            <h4 style={{ textAlign: "center" }}>
                                {
                                    currentProcessMessage
                                }
                            </h4>
                            <div style={{

                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                justifyContent: "center",
                            }}>


                                <Card style={{
                                    width: "100%",
                                    display: "flex", flexDirection: "column",
                                    margin: "auto",
                                    height: "25dvh",
                                    padding: "20px",
                                    overflow: "auto",
                                }}>
                                    {
                                        console_messages && console_messages.map((message, index) => <p key={index} style={{
                                            color: eduCOBOTColorScheme.gray
                                        }}>{message}</p>)
                                    }

                                </Card>
                            </div>
                        </div>

                    </>
                }
            </div>
        </motion.div>
    )
}

export default CodeGenerator