import React, { useContext, useEffect } from 'react'
import { BlocklyWorkspace } from "react-blockly";
import { SettingContext } from '../../context/SettingsContext';
import MicroPythonToolBox from './configs/micropython-toolbox';
import { microPythonResolver } from './configs/code-generator';
import DarkThemeBlockly from '../../theme/blockly/dark';
import Blockly from './configs/micropython-blocks';
import './configs/micropython-editor-styling.css'
type Props = {
    initialXML?: string,
    setCode: (code: string) => void,
    setXml: (xml: string) => void,
    setNewBlocks?: (value: string[]) => void,
    microPythonToolBox?: typeof MicroPythonToolBox
}
function MicroPythonEditor({
    initialXML,
    setCode,
    setXml,
    setNewBlocks,
    microPythonToolBox
}: Props) {

    const [toolBox, setToolBox] = React.useState(microPythonToolBox || MicroPythonToolBox)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [initialXMLValue, setInitialXML] = React.useState(initialXML)
    useEffect(() => {
        // if (!!localStorage.getItem("micropython-code")) {
        //     setCode(localStorage.getItem("micropython-code") as string);
        // }
        // if (!!localStorage.getItem("micropython-xml")) {
        //     let xml = localStorage.getItem("micropython-xml") as string
        //     setCode(xml);
        //     setInitialXML(xml);
        // }
    }, [])



    useEffect(() => {
        setToolBox(microPythonToolBox || MicroPythonToolBox)
    }, [microPythonToolBox])


    return (
        <div style={{
            height: "100%",
            width: settings.screen === "mobile" ? "100%" : "50vw",
            display: "flex",
        }}>
            <BlocklyWorkspace
                key={initialXMLValue}
                toolboxConfiguration={
                    toolBox
                }
                initialXml={initialXMLValue}
                className="micro-python-editor"
                workspaceConfiguration={{
                    horizontalLayout: false,
                    trashcan: true,
                    zoom: {
                        controls: true,
                        wheel: true,
                        startScale: 1.0,
                        maxScale: 3,
                        minScale: 0.3,
                        scaleSpeed: 1.2,
                        pinch: true,
                    },
                    move: {
                        scrollbars: {
                            horizontal: true,
                            vertical: true
                        },
                        drag: true, wheel: false
                    },
                    renderer: "zelos",
                    theme: settings.themeMode === "dark" ? DarkThemeBlockly : Blockly.Themes.Classic,
                }}
                onWorkspaceChange={(workspace) => {
                    const code = microPythonResolver.getPythonCode(workspace)
                    setCode(code)
                    setNewBlocks && setNewBlocks(microPythonResolver.getNewBlocks())
                    localStorage.setItem("micropython-code", code)
                    const elements = document.getElementsByClassName('blocklyTreeRow')
                    for (let index = 0; index < elements.length; index++) {
                        const element = elements[index] as HTMLDivElement;
                        const color = element.style.borderLeftColor
                        if (color === "rgb(221, 221, 221)") {
                            element.style.backgroundColor = "transparent"
                            const targetElement = element.getElementsByClassName('blocklyTreeLabel')[0] as HTMLSpanElement
                            
                            targetElement.style.fontWeight = `bolder`;
                            targetElement.style.background = `transparent`;
                            const targetElement2 = element.getElementsByClassName('blocklyTreeIcon')[0] as HTMLSpanElement
                            targetElement2.style.backgroundImage = "url('https://educobot-robotics.s3.ap-south-1.amazonaws.com/driver-software/Code_Icon%2B(1).png') "
                            targetElement2.style.backgroundSize = "cover"
                            targetElement2.style.visibility = "visible";
                        } else {
                            const targetElement = element.getElementsByClassName('blocklyTreeIcon')[0] as HTMLSpanElement
                            targetElement.style.background = `${color}`;
                            targetElement.style.visibility = "visible";
                            targetElement.style.borderRadius = "3px";
                            // console.log(targetElement);
                            // console.log(color);
                        }
                    }
        
                }}
                onXmlChange={(xml) => {
                    // console.log(xml);
                    setXml(xml);
                    localStorage.setItem("micropython-xml", xml);
                }}
            />
        </div>
    )
}

export default MicroPythonEditor