import React, { useContext, useEffect } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Card, CardActions } from '@mui/material';
import { eduCOBOTColorScheme } from '../../../context/SettingsContext';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import { UserContext } from '../../../context/UserContext';
import { SettingContext } from '../../../context/SettingsContext';
import { BackendConfigs } from '../../../config.environment';
import { APIResponse } from '../../../types/Response';
import { ContentDialogBox } from '../../../components/dialog_box';
import { toast } from 'react-toastify';

type Props = {
    setIsDataChanged: React.Dispatch<React.SetStateAction<any>>
}


type projectData = {
    _id: string
    name: string
    description: string
    ownerId: string
    createdAt: string
    lastUpdatedAt: string
    isPublic: boolean
    sensorsList: string[]
    microPythonXML: string
    microPythonCode: string
}



function ProjectListComponent({ setIsDataChanged }: Props) {
    const navigate = useNavigate()
    const { user } = useContext(UserContext)
    const {settings, handleGlobalLoading} = useContext(SettingContext)
    const [projectList, setProjectList] = React.useState<projectData[]>([])

    async function fetchProjects() {
        handleGlobalLoading(true,"Fetching Projects")
        setProjectList([])
        try {
            const response = await fetch(`${BackendConfigs.url}/GetProjectsByOwnerIdAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                setProjectList(result.data)
            }
        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        fetchProjects()
    }, [])
    return (
        <div>
            <div style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
                flexWrap: "wrap"
            }}>
                {
                    projectList.map(project => {
                        return <ProjectCard key={project._id} data={project} reFetch={fetchProjects} />
                    })
                }
                {
                    projectList.length === 0 && <Card style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "30vh",
                        margin: "10px",
                        padding: "10px",
                        boxShadow: "0 0 5px #ccc",
                        borderRadius: "10px"
                    }}>

                        <p style={{ textAlign: "center" }}>No Projects Found</p>
                    </Card>
                }
            </div>
        </div>
    )
}




function ProjectCard({ data, reFetch }: {
    data: projectData,
    reFetch: () => void
}) {
    const navigate = useNavigate()
    const {user}=useContext(UserContext)
    const {settings, handleGlobalLoading} = useContext(SettingContext)


    return (
        <>
            <Card style={{
                width: "min(300px, 100%)",
                height: "min(200px, 500px)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "20px",
                margin: "10px",
                background: "transparent",
                backdropFilter: "blur(2px)",
                transition: "all 0.3s",
            }}
            sx={{
                ":hover":{
                    scale: "1.1",
                }
            }}
            >
                <img src="https://innovator.educobot.com/Innovator_Bulb.png" alt="" style={{
                    width: "100px",
                    height: "100px",
                    marginBottom: "20px",
                }} />
                <CardActions style={{
                    width: "100%",
                    background: "#DDDDDD",
                    textAlign: "center",
                    marginBottom: "0px",
                    padding: "15px",
                    position: "absolute",
                    bottom: "0px",
                }} onClick={() => {
                    navigate(routes.DASHBOARD.PROJECT.ROOT + "/" + data._id)
                }}>
                    <h5 style={{
                        width: "100%",
                        color: eduCOBOTColorScheme.gray,
                        textAlign: "center",
                    }} >
                        {
                            data.name
                        }
                    </h5>
                </CardActions>
            </Card>



        </>

    )
}

export default ProjectListComponent