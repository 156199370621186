import { CardMedia, Container, Stack, ToggleButton } from '@mui/material'
import React, { useContext } from 'react'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { UserContext } from '../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../context/SettingsContext';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';

type Props = {}

function Header({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    return (
        <div>
            <Container sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: "10px",
                position: "sticky"
            }}>
                <Link to={routes.ROOT}>
                    <h2 style={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "center",
                    }}>

                        <CardMedia
                            component="img"
                            image="/Innovator_Bulb.png"
                            sx={{
                                width: "50px",
                            }}
                        />
                        <h4  >
                            Innovator
                        </h4>
                    </h2>
                </Link>
                <nav style={{
                    width: "100%",
                    maxWidth: "500px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center"
                }}>
                    <Link to={routes.DASHBOARD.ROOT}>
                        Dashboard
                    </Link>
                </nav>
                <div>
                    <Stack direction="row" spacing={5}>
                        <ToggleButton
                            style={{ borderRadius: "50px", border: "none", color:eduCOBOTColorScheme.black
                             }}
                            value="check"
                            onChange={toggleTheme}
                        >
                            {settings.themeMode === "dark" && <LightModeIcon color={"primary"} />}
                            {settings.themeMode === "light" && <DarkModeIcon color={"primary"} />}
                        </ToggleButton>
                    </Stack>
                </div>
            </Container>
        </div>

    )
}

export default Header