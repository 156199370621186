import React, { useContext, useEffect, useState } from 'react'
import { ProjectDataType, WidgetDataType } from '../../../../types/Dashboard';
import { GridItemHTMLElement, GridStack } from 'gridstack';
import 'gridstack/dist/gridstack.css';
import 'gridstack/dist/gridstack-extra.min.css';
import { UserContext } from '../../../../context/UserContext';
import { SettingContext } from '../../../../context/SettingsContext';
import { Card } from '@mui/material';
import GaugeComponent from './Wigets/Gauge';
import IndicatorComponent from './Wigets/Indicator';
import NotificationComponent from './Wigets/Notification';
import './style.css'
import LabelComponent from './Wigets/Label';
import SwitchComponent from './Wigets/Switch';
import TextInputComponent from './Wigets/TextInput';
import CommentComponent from './Wigets/Comment';
import BarGraphComponent from './Wigets/BarGraph';
import LineGraphComponent from './Wigets/LineGraph';

type Props = {
    project: ProjectDataType,
    widgets: WidgetDataType[],
    setWidgets: React.Dispatch<React.SetStateAction<WidgetDataType[]>>,
    deleteWidget: (index: string) => void,
    previewMode: boolean,
    reRender?:()=>void
}





function MonitorDesigner({
    project,
    widgets,
    setWidgets,
    deleteWidget,
    previewMode,
    reRender
}: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    // const []
    let grid: any
    function getWidgetComponent(widget_type: string, widget: WidgetDataType, widgets: WidgetDataType[], setWidgets: React.Dispatch<React.SetStateAction<WidgetDataType[]>>, deleteWidget: (index: string) => void, project: ProjectDataType) {
        switch (widget_type) {
            case "Gauge":
                return <GaugeComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
            case "Label":
                return <LabelComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
            case "Switch":
                return <SwitchComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
            case "Text Input":
                return <TextInputComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
            case "Comment":
                return <CommentComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
            case "Bar Chart":
                return <BarGraphComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
            case "Line Chart":
                return <LineGraphComponent previewMode={previewMode} widget={widget} setWidgets={setWidgets} widgets={widgets} deleteWidget={() => { deleteWidget(widget.widgetId) }} project={project} />
        }
        return <></>
    }


    useEffect(() => {
        grid = GridStack.init({
            disableDrag: previewMode,
            disableResize: previewMode,
            acceptWidgets: true,
            float: true,
            cellHeight: 82,
            column: 12,
            minRow: 10,
            columnOpts: {
                breakpoints: [
                    {
                        w: 1200,
                        c: 6,
                    },
                    {
                        w: 768,
                        c: 1,
                    },
                ]
            }
        });
        grid.on('resize', function (event: Event, element: GridItemHTMLElement) {
            console.log("resize");
            const gsX = (element.getAttribute("gs-x") || "0") as string;
            const gsY = (element.getAttribute("gs-y") || "0") as string;
            const gsW = (element.getAttribute("gs-w") || "0") as string;
            const gsH = (element.getAttribute("gs-h") || "0") as string;
            const id = element.id;
            console.log(gsX, gsY, gsW, gsH, id);
            setWidgets((preData) => {
                const newData = preData.map((widget: WidgetDataType) => {
                    if (widget.widgetId === id) {
                        return {
                            ...widget,
                            row: parseInt(gsY),
                            column: parseInt(gsX),
                            numberOfColumns: parseInt(gsW),
                            numberOfRows: parseInt(gsH)
                        }
                    } else {
                        return widget
                    }
                })
                console.log("resize", newData);
                return newData
            })
        });
        grid.on('dropped', function (event: Event, element: GridItemHTMLElement) {
            console.log("dropped", event.target, element);
            console.log((event.target as HTMLDivElement).childNodes)
            const newlyAddedNode = (event.target as HTMLDivElement).childNodes[(event.target as HTMLDivElement).childNodes.length - 1] as HTMLDivElement
            const gsX = (newlyAddedNode.getAttribute("gs-x") || "0") as string;
            const gsY = (newlyAddedNode.getAttribute("gs-y") || "0") as string;
            console.log(newlyAddedNode.id);
            if(newlyAddedNode.id.toLowerCase().includes("chart")) {
                setWidgets((preData) => {
                    const newWidget = {
                        widgetId: Date.now().toString(),
                        widgetType: newlyAddedNode.id,
                        widgetColor: "red",
                        linkedVariable: "",
                        variableValue: "",
                        numberOfColumns: 10,
                        numberOfRows: 6,
                        row: parseInt(gsY),
                        column: 0,
                        widgetTitleText: newlyAddedNode.id,
                    }
                    console.log("newWidget", [...preData, newWidget]);
                    return [...preData, newWidget]
                })
            }else{
            setWidgets((preData) => {
                const newWidget = {
                    widgetId: Date.now().toString(),
                    widgetType: newlyAddedNode.id,
                    widgetColor: "red",
                    linkedVariable: "",
                    variableValue: "",
                    numberOfColumns: 3,
                    numberOfRows: 3,
                    row: parseInt(gsY),
                    column: parseInt(gsX),
                    widgetTitleText: newlyAddedNode.id,
                }
                console.log("newWidget", [...preData, newWidget]);
                return [...preData, newWidget]
            })
        }
            setTimeout(() => {
                reRender&&reRender()
            },500)
        });
        grid.on("dragstop", function (event: Event, element: GridItemHTMLElement) {
            console.log("dragstop", element);
            const gsX = (element.getAttribute("gs-x") || "0") as string;
            const gsY = (element.getAttribute("gs-y") || "0") as string;
            const gsW = (element.getAttribute("gs-w") || "0") as string;
            const gsH = (element.getAttribute("gs-h") || "0") as string;
            const id = element.id;
            console.log(gsX, gsY, gsW, gsH, id);

            setWidgets((preData) => {
                const newData = preData.map((widget: WidgetDataType) => {
                    if (widget.widgetId === id) {
                        return {
                            ...widget,
                            row: parseInt(gsY),
                            column: parseInt(gsX),
                            numberOfColumns: parseInt(gsW),
                            numberOfRows: parseInt(gsH)
                        }
                    } else {
                        return widget
                    }
                })
                console.log("dragstop", newData);

                return newData
            })
        })
        GridStack.setupDragIn(".widget-box .grid-stack-item", {
            appendTo: "body",
            helper: "clone"
        })
    }, [])
    return (
        <div
            style={{
                height: "100%",
                maxHeight: "92dvh",
                // border: "2px solid red",
                overflow: "auto"
            }}
        >



            <div style={{
                display: "grid",
                gridTemplateColumns: previewMode ? "1fr" : settings.screen !== "mobile" ? "1fr 7fr" : "1fr",
                // height: "100%",
            }}>
                {
                    settings.screen !== "mobile" && !previewMode &&
                    <div style={{
                        border: "1px solid gray",
                        height: "100%",
                    }}>
                        <h5 style={{
                            padding: "10px",
                        }}>Widgets</h5>
                        <div style={{
                            height: "100%",
                            maxHeight: "83dvh",
                            overflowY: "auto",
                        }}>
                            <div className='widget-box' style={{
                                width: "100%",
                                // minHeight: "100%"
                            }}>
                                <MonitoringComponent title='Switch' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Switch.svg' />
                                <MonitoringComponent title='Gauge' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Gauge.svg' />
                                <MonitoringComponent title='Label' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Label.png' />
                                <MonitoringComponent title='Text Input' image='https://cdn0.iconfinder.com/data/icons/thin-text-editing/24/thin-0256_input_box_text_edit-512.png' />
                                <MonitoringComponent title='Comment' image='https://cdn-icons-png.flaticon.com/512/1380/1380338.png' />
                                {/* <MonitoringComponent title='Stepper' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Stepper.svg' /> */}
                                {/* <MonitoringComponent title='Slider' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Slider_1.svg' /> */}
                                {/* <MonitoringComponent title='Push Button' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Push+Button.svg' /> */}
                                <MonitoringComponent title='Line Chart' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Graph.svg' />
                                <MonitoringComponent title='Bar Chart' image='https://cdn.iconscout.com/icon/free/png-256/free-chart-growth-1913955-1624750.png?f=webp' />
                                {/* <MonitoringComponent title='Date Picker' image='https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/widgets/Time+Picker.svg' /> */}
                                {/* <MonitoringComponent title='Scheduler' image='https://cdn-icons-png.flaticon.com/512/3652/3652191.png' /> */}
                            </div>
                        </div>
                    </div>

                }
                <div style={{
                    // height: previewMode ? "95dvh" : "95dvh",
                    border: "1px solid #00000055", 
                    height: "100%",
                    maxHeight: "92dvh",
                    overflow: "scroll",
                    position: "relative",
                    display: "flex",
                    flexWrap: "wrap",
                }} id='monitor-designer-grid-stack'>
                    <div className="grid-stack" style={{
                        width: "100%",
                        height: "100%",
                        minHeight: "92dvh",
                        // minHeight: previewMode ? "9dvh" : "95dvh",
                        // border: "1px solid green",
                    }}>
                        {
                            Object.values(widgets).map((widget, index) => {

                                return <div key={index} id={`${widget.widgetId}`} className="grid-stack-item" style={{
                                    cursor: "grab",
                                    zIndex: 99

                                }} gs-x={widget.column} gs-y={widget.row} gs-w={widget.numberOfColumns} gs-h={widget.numberOfRows}>
                                    <div className="grid-stack-item-content">
                                        {getWidgetComponent(widget.widgetType, widget, widgets, setWidgets, deleteWidget, project)}
                                    </div>
                                </div>

                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MonitorDesigner












const MonitoringComponent = ({ title, image }: {
    title: string,
    image: string,

}) => {
    return (
        <div id={title} className='grid-stack-item' gs-w="2" gs-h="2" style={{
            userSelect: "none",
        }}>
            <Card sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                borderRadius: "10px",
                position: "relative",
                margin: "10px",
                height: "150px",
                padding: "10px",
            }} >
                <h5>{title}</h5>
                <img src={image} alt="" style={{
                    maxWidth: "60px",
                    maxHeight: "60px"
                }} />

            </Card>
        </div>

    )
}