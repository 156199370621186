import MonitoringHeader from './MonitoringHeader'
import MonitorDesigner from './MonitorDesigner'
import MonitoringFooter from './MonitoringFooter'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../../context/UserContext'
import { SettingContext } from '../../../../context/SettingsContext'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectDataType, WidgetDataType } from '../../../../types/Dashboard'
import { BackendConfigs } from '../../../../config.environment'
import { APIResponse } from '../../../../types/Response'
import { Button, Tooltip, Typography } from '@mui/material'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import MonitorIcon from '@mui/icons-material/Monitor';
import PreviewIcon from '@mui/icons-material/Preview';
import SaveIcon from '@mui/icons-material/Save';
import Preview from './Preview'
import { motion } from 'framer-motion';
import { framerAnimation } from '../../../../theme/animation/MotionConfigs'
import { toast } from 'react-toastify'

type Props = {}

function MonitoringWorkspacePage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [widgets, setWidgets] = useState<WidgetDataType[]>([])
    const [previewMode, setPreviewMode] = useState(false)
    const [reRender, setRerender] = useState(false)
    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Loading Project")
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    console.log(result.data);
                    setProjectData(result.data)
                    setWidgets((result.data as ProjectDataType).monitor.widgets)
                }
            } catch (error) {
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    async function saveWidgets() {
        handleGlobalLoading(true, "Saving Widgets")
        console.log(widgets);
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeMonitorJsonAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    projectId: projectData?.project._id,
                    widgets: widgets
                })
            })
            const result = await response.json() as APIResponse
            if (result.success) {
                toast.success(result.message)
                getProject()
            } else {
                toast.error(result.message)
            }
        } catch (error) {
            console.log(error);
            toast.error("Error saving variables")
        }
        handleGlobalLoading(false)
    }
    useEffect(() => {
        // handleGlobalLoading(true, "Loading Project")
        getProject()
        // const interval = setInterval(() => {
        //     getProject()
        // }, 2000)
        // return () => clearInterval(interval)
    }, [user, projectId])
    function deleteWidget(widgetId: string) {
        setWidgets((prev) => {
            return prev.filter((widget) => widget.widgetId !== widgetId)
        })
    }
    return (
        <div style={{
            height: "100dvh",
            display: 'flex',
            border: "1px solid gray",
            // justifyContent: "center",
            flexDirection: "column"
        }}>

            {
                projectData && <MonitoringHeader refreshProject={getProject} project={projectData} previewMode={previewMode} />
            }
            {
                previewMode ? <>
                    <motion.div
                        initial={framerAnimation.left2right.initial}
                        animate={framerAnimation.left2right.animate}
                        exit={framerAnimation.left2right.exit}
                        transition={framerAnimation.left2right.transition}
                        style={{
                            height: "100%",
                        }}
                    >
                        {
                            projectData && <MonitorDesigner key={previewMode ? "2" : "3"} deleteWidget={deleteWidget} project={projectData} widgets={widgets} setWidgets={setWidgets} previewMode={previewMode} reRender={() => {
                                setRerender(!reRender)
                            }} />
                        }
                    </motion.div>
                </> : <div style={{
                    height: "100%",
                }}>

                    {
                        projectData && <MonitorDesigner key={reRender ? "2" : "3"} deleteWidget={deleteWidget} project={projectData} widgets={widgets} setWidgets={setWidgets} previewMode={previewMode} reRender={() => {
                            setRerender(!reRender)
                        }} />
                    }
                </div>
            }

            <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: 'center', height: "100vh", position: "fixed", top: "0", right: 0 }}>
                <Typography style={{
                    backgroundColor: "white",
                    minHeight: "10vh",
                    border: "0.5px solid #00000014",
                    display: "flex",
                    flexDirection: "column",
                }}>
                    <Tooltip title={previewMode ? "Close Preview" : "Open Preview"} placement='left'>
                        <Button style={{}} onClick={() => {
                            setPreviewMode(!previewMode)
                        }}>
                            {
                                previewMode ? <PreviewIcon /> : <MonitorIcon />
                            }
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Save Monitor"} placement='left'>
                        <Button style={{}} onClick={() => {
                            saveWidgets()
                            // setIsSavingTab(!isSavingTab)
                        }}>
                            <SaveIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title={"Reset to last Saved Design"} placement='left'>
                        <Button style={{}} onClick={() => {
                            // setCode(projectData?.project.microPythonCode ?? "")
                            // setXml(projectData?.project.microPythonXML ?? "")
                            // setCircuitJSON(projectData?.circuit.circuitJSON ?? "")
                            // setRefreshData(!refreshData)
                        }}>
                            <RotateLeftIcon />
                        </Button>
                    </Tooltip>
                </Typography>
            </div>
            <MonitoringFooter />
        </div>
    )
}

export default MonitoringWorkspacePage