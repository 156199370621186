import React, { useContext, useState } from 'react'
import DashboardNavbar from './DashboardNavbar'
import DashboardTopbar from './DashboardTopbar'
import { SettingContext } from '../../../context/SettingsContext'
import { SwippleDrawer } from '../../../components/drawers'
type Props = {
    children?: React.ReactNode,
    title?: string
}

function DashboardLayout(props: Props) {
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    const [isNavBarOpen, setIsNavBarOpen] = useState(settings.screen === "desktop" ? true : false)
    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: settings.screen === "mobile" ? "1fr" : "2fr 10fr",
                height: "100vh",
            }}
        >
            {
                settings.screen === "mobile" ?
                    <SwippleDrawer
                        isOpen={isNavBarOpen}
                        onClose={() => setIsNavBarOpen(false)}
                    >
                        <DashboardNavbar />
                    </SwippleDrawer>
                    :
                    <DashboardNavbar />
            }
            <div style={{
                height: "100vh",
                overflow: "auto",
                padding: "0 10px",
                background: "url(https://mir-s3-cdn-cf.behance.net/project_modules/1400/5137c469288297.5b7c3e98bee9d.gif) no-repeat center center",
                backgroundSize: "cover",

            }}>
                <DashboardTopbar title={props.title as string} toggleNavBarOpen={() => {
                    setIsNavBarOpen(!isNavBarOpen)
                }} />
                <div style={{
                    height: "90vh",
                    overflow: "auto",
                    padding: "10px",
                }}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default DashboardLayout