import React, { useContext, useEffect, useState } from 'react'
import { SettingContext } from '../../context/SettingsContext'
import { Button, Card, Typography } from '@mui/material'
import MicroPythonEditor from '../../components/micropython-block-editor'
import CodeEditor from '../../components/code-editor'
import { ContentDialogBox } from '../../components/dialog_box'
import { deployCode } from '../../components/micropython-block-editor/RoboticHandler'

type Props = {}

export default function MicroPythonEditorPage({ }: Props) {
    const [xml, setXml] = React.useState('')
    const [code, setCode] = React.useState('')
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const [isBlockEditing, setIsBlockEditing] = React.useState(true)
    const [newBlocks, setNewBlocks] = useState<string[]>([])
    const [isControllerOpen, setIsControllerOpen] = React.useState(false)
    const [isExamplesOpen, setIsExamplesOpen] = React.useState(false)
    async function deployCodeOnMachine() {
        handleGlobalLoading(true)
        await deployCode(code)
        handleGlobalLoading(false)
    }
    return (
        <div>
            <div className="header" style={{
                minHeight: "5vh",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: 5,
                borderBottom: "1px solid gray",
            }}>
                <h4>Robotics Playground</h4>
                <nav>
                    <Button onClick={() => { setIsExamplesOpen(true) }} size='small' variant='contained' sx={{
                        textTransform: "none",
                        background:"#2065D1",
                        borderRadius:"6px",
                        fontWeight:"bolder",
                        fontSize:"10px"
                    }}>
                        Samples
                    </Button>
                </nav>
            </div>
            <div style={{
                display: "flex",
                height: "95vh",
            }}>
                <MicroPythonEditor setXml={setXml} setCode={(code: string) => {
                    isBlockEditing && setCode(code)
                }} setNewBlocks={(value) => {
                    isControllerOpen && setNewBlocks(value)
                }} />
                <div style={{
                    height: "90vh",
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}>
                        <Button style={{
                            border: "none",
                        }} onClick={() => {
                            deployCodeOnMachine()
                        }}>
                            <img src="https://webapp.educobot.com/_next/image?url=%2Fassets%2Fgreen_flag.png&w=32&q=75" style={{
                                width: "15px"
                            }} alt="" />
                        </Button>

                        <Button style={{
                            border: "none",
                        }} onClick={() => {
                            setIsControllerOpen(!isControllerOpen)
                        }}>
                            <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Game+Pad.png" style={{
                                width: "20px"
                            }} alt="" />
                        </Button>
                        <Button variant='outlined' onClick={() => {
                            setIsBlockEditing(!isBlockEditing)
                        }} style={{
                            border: "none",
                            width: "50px"
                        }}>
                            {
                                isBlockEditing ?
                                    <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/innovator-new/block_Icon.png" height={25} alt="" />
                                    : <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Code_Icon+(1).png" height={25} alt="" style={{
                                        filter: "invert(1)",
                                    }} />
                            }
                        </Button>
                    </div>
                    <div>

                    </div>
                    <CodeEditor code={code}
                        setCode={setCode} />

                </div>
            </div>
            <ContentDialogBox
                isOpen={isControllerOpen}
                onClose={() => { setIsControllerOpen(false) }}
                content={
                    <>
                        <ControllerComponent newBlocks={newBlocks} />
                    </>
                }
                title='Your Controller'
                maxWidth="xs"
            />
            <ContentDialogBox
                isOpen={isExamplesOpen}
                onClose={() => { setIsExamplesOpen(false) }}
                isTransition={true}
                transitionDirection='left'
                content={
                    <>
                        <Typography style={{
                            display: "flex",

                        }}>

                            <Card style={{
                                width: "250px",
                                minHeight: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "15px",
                                margin: "10px",
                                borderRadius: "20px"
                            }}>
                                <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                    maxWidth: "100px",
                                }} alt="" />
                                <h5 style={{
                                    margin:"10px"
                                }}>Smart Car</h5>
                                <Button variant='outlined' color='success' sx={{
                                    textTransform: "none",
                                    fontSize:"10px"
                                    
                                }} size='small'>
                                    Try
                                </Button>
                            </Card>
                            <Card style={{
                                width: "250px",
                                minHeight: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "15px",
                                margin: "10px",
                                borderRadius: "20px"
                            }}>
                                <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                    maxWidth: "100px",
                                }} alt="" />
                                <h5 style={{
                                    margin:"10px"
                                }}>Smart House</h5>
                                <Button variant='outlined' color='success' sx={{
                                    textTransform: "none",
                                    fontSize:"10px"
                                }} size='small'>
                                    Try
                                </Button>
                            </Card>
                            <Card style={{
                                width: "250px",
                                minHeight: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "15px",
                                margin: "10px",
                                borderRadius: "20px"
                            }}>
                                <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                    maxWidth: "100px",
                                }} alt="" />
                                <h5 style={{
                                    margin:"10px"
                                }}>Keypad</h5>
                                <Button variant='outlined' color='success' sx={{
                                    textTransform: "none",
                                    fontSize:"10px"
                                }} size='small'>
                                    Try
                                </Button>
                            </Card>
                            <Card style={{
                                width: "250px",
                                minHeight: "150px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                padding: "15px",
                                margin: "10px",
                                borderRadius: "20px"
                            }}>
                                <img src="https://innovator-resourses.s3.ap-south-1.amazonaws.com/assets/Thumbnail_Innovator_Bulb.png" style={{
                                    maxWidth: "100px",
                                }} alt="" />
                                <h5 style={{
                                    margin:"10px"
                                }}>DHT11</h5>
                                <Button variant='outlined' color='success' sx={{
                                    textTransform: "none",
                                    fontSize:"10px"
                                }} size='small'>
                                    Try
                                </Button>
                            </Card>
                        </Typography>
                    </>
                }
                actions={<></>}
                title='Samples'
                fullScreen
            />

        </div>
    )
}





export function ControllerComponent({
    newBlocks
}: {
    newBlocks: string[]
}) {
    return (
        <Typography style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            gap: 10,
            rowGap: 50,
        }}>
            {
                newBlocks.map(block => {
                    return <Button variant='contained' color={'info'} style={{
                        width: "150px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "auto",
                        borderRadius: "15px"
                    }} sx={{
                        textTransform: "none"
                    }}>
                        {block}
                    </Button>
                })
            }
        </Typography>
    )
}