import React from 'react'
import ReactApexChart from 'react-apexcharts'

type Props = {
    data: Array<number>,
    labels: Array<string>,
    yLabel: string,
    xLabel: string,
    height?: string | number,
    valueType?: string,
    graphType?: "line" | "bar",
    yStepSize?: number
}

export default function GraphComponent({
    data,
    labels,
    yLabel,
    xLabel = "",
    height = 350,
    valueType = "",
    graphType = "line",
    yStepSize = 2,

}: Props) {
    return (
        <div style={{
            backdropFilter: "blur(5px)",
        }}>
            <ReactApexChart type={graphType} width={"100%"} height={height} series={[{
                name: '',
                data: data
            }]}
                options={{
                    chart: {
                        type: graphType,
                        height: 350,
                    },
                    plotOptions: {
                        bar: {
                            horizontal: false,
                            columnWidth: '55%',
                        },
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    xaxis: {
                        categories: labels,
                        title: {
                            text: xLabel,
                        },
                    },
                    yaxis: {
                        title: {
                            text: yLabel
                        },
                        min: 0,
                        forceNiceScale: true,
                        stepSize: yStepSize
                    },
                    fill: {
                        opacity: 1
                    },
                    tooltip: {
                        y: {
                            formatter: function (val) {
                                return val +" "+ valueType
                            }
                        }
                    }
                }}
            /></div>
    )
}