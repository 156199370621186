import { Button, Card } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { eduCOBOTColorScheme } from '../../../context/SettingsContext'
import { ProjectDataType } from '../../../types/Dashboard'
import { format, formatDistance, formatRelative, subDays, subHours } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { routes } from '../../../routes/routes'


export function getImagePathByComponentName(name: string) {
    if (name === "eduCOBOT-Shield") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Sheild_with_ESP32.svg"
    }
    if (name === "esp32") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ESP32_Vertical.svg"
    }
    if (name === "IR-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/IR+Sensor.svg"
    }
    if (name === "LDR-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LDR_PCB.svg"
    }
    if (name === "DHT11-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/DHT11.svg"
    }
    if (name === "MQ2-Sensor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Smoke_Sensor.svg"
    }
    if (name === "RFID") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/RFID.svg"
    }
    if (name === "UltraSonic") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Ultrasonic.svg"
    }
    if (name === "Keypad") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Key_Pad.svg"
    }
    if (name === "LCD") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LCD.svg"
    }
    if (name === "LED") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/LED_PCB.svg"
    }
    if (name === "Buzzer") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Buzzer_PCB.svg"
    }
    if (name === "OLED") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/OLED.svg"
    }
    if (name === "Servo") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/SERVO_MOTOR.svg"
    }
    if (name === "Accelerometer") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/ADXL345.svg"
    }
    if (name === "BO-Motor") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/BO+Motor.svg"
    }
    if (name === "Push-Button") {
        return "https://educobot-robotics.s3.ap-south-1.amazonaws.com/images/robotic-components/Tactile+Switch.svg"
    }
    return ""
}















type Props = {
    project: ProjectDataType
}

function ProjectDescription({ project }: Props) {
    const [isModuleActive, setIsModuleActive] = useState(false)
    const navigate = useNavigate()
    function handleActiveStatus() {
        if (!project) return
        if (!project.lastInteraction) return
        if (Date.now() - parseInt(project.lastInteraction) < 300000) {
            setIsModuleActive(true)
        }
    }
    useEffect(() => {
        handleActiveStatus()


    }, [project])
    return (
        <div style={{
            minHeight: "30vh",
            display: 'flex',
            margin: "0 10px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "95%"
        }}>
            <Card style={{
                minHeight: "150px",
                width: "100%",
                margin: "10px",
                padding: "10px",
                boxShadow: "0 0 5px #ccc",
                borderRadius: "10px",
                background: 'transparent',
                backdropFilter: 'blur(2px)',
            }}>
                <div style={{
                    margin: "0 10px",
                }}>
                    <h4 id='project-title' style={{
                        margin: "10px 0",
                        textAlign: "center",
                    }}>{project.project.name}</h4>
                    <p id='project-creation-time' style={{
                        fontSize: "10px",
                        fontWeight: "bold",
                        color: eduCOBOTColorScheme.blue,
                        textDecoration: 'underline',
                        margin: "10px 0",
                        // textAlign:"center"
                    }}>
                        Created   {formatDistance(new Date(parseInt(project.project.createdAt)), new Date(), { addSuffix: true })}
                    </p>
                    <h5 id='project-description' style={{
                        color: eduCOBOTColorScheme.gray,
                        fontSize: "12px",
                    }}>{project.project.description}</h5>
                </div>
                <hr style={{
                    width: "100%",
                    border: "0.5px solid gray",
                    margin: "20px 0"
                }} />
                <div id='components-list' style={{
                    display: "flex",
                    justifyContent: "start",
                    flexWrap: "wrap",
                    margin: "0 10px",

                }}>
                    <h6 style={{
                        width: "100%",
                    }}>Components Used</h6>

                    {
                        project.circuit.sensorsList.length === 0 && <>

                            <Card style={{
                                width: "100%",
                                minHeight: "120px",
                                display: "flex",
                                padding: "10px",
                                margin: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                borderRadius: "10px",
                                fontSize: "15px",

                            }}>
                                No Sensors are Linked Yet
                            </Card>
                        </>
                    }
                    {
                        project.circuit.sensorsList.map((sensor, index) => {
                            return (
                                <Card style={{
                                    width: "200px",
                                    minHeight: "120px",
                                    display: "flex",
                                    padding: "10px",
                                    margin: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    borderRadius: "10px"

                                }}>
                                    <img src={getImagePathByComponentName(sensor)} style={{
                                        maxHeight: "100px",
                                        maxWidth: "180px"
                                    }} alt="" />
                                    <h6>{sensor}</h6>
                                </Card>
                            )
                        })
                    }

                </div>

                <div style={{
                    width: "100%",
                    minHeight: "20vh",
                    display: 'flex',
                    margin: "0 10px",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }} id='device-active-status'>


                    <div style={{
                        minHeight: "20vh",
                        width: "100%",
                        display: 'flex',
                        margin: "0 10px",
                        justifyContent: "start",
                        alignItems: "center",
                        flexDirection: "row",
                        flexWrap: "wrap",
                    }}>
                        <h6 style={{
                            width: "100%",
                        }}>Current Status</h6>
                        <Card style={{
                            width: "200px",
                            height: "120px",
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "10px",
                            padding: "10px",
                            boxShadow: "0 0 5px #ccc",
                            borderRadius: "10px"
                        }}>
                            <div style={{
                                fontSize: "12px",
                            }}>
                                Device Status : {
                                    isModuleActive?"Active":"inactive"
                                }
                            </div>
                            <img src={isModuleActive?"https://cdn-icons-png.flaticon.com/128/8622/8622132.png":"https://vectorified.com/images/status-icon-33.png"} width={40} alt="" />
                        </Card>
                        {/* {
                            project.project.sensorsList.length === 0 && <>

                                <Card style={{
                                    width: "100%",
                                    minHeight: "120px",
                                    display: "flex",
                                    padding: "10px",
                                    margin: "10px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    flexDirection: "column",
                                    borderRadius: "10px"

                                }}>
                                    No Sensors are Linked Yet
                                </Card>
                            </>
                        } */}



                        {/* 
                        <Card style={{
                            width: "200px",
                            height: "120px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "10px",
                            padding: "10px",
                            boxShadow: "0 0 5px #ccc",
                            borderRadius: "10px"
                        }}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                fontSize: "12px",
                                width: "100%"
                            }}>
                                <span>Sensor Name: </span> <span>DHT 11</span>
                            </div>
                            <hr style={{
                                width: "100%",
                                border: "0.5px solid gray"
                            }} />
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                fontSize: "12px",
                                width: "100%"
                            }}>
                                <span>Status :</span> <span style={{
                                    color: "green"
                                }}>Active</span>
                            </div>

                            <hr style={{
                                width: "100%",
                                border: "0.5px solid gray"
                            }} />
                        </Card>
                        <Card style={{
                            width: "200px",
                            height: "120px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "10px",
                            padding: "10px",
                            boxShadow: "0 0 5px #ccc",
                            borderRadius: "10px"
                        }}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                fontSize: "12px",
                                width: "100%"
                            }}>
                                <span>Sensor Name: </span> <span>Soil Moisture Sensor</span>
                            </div>
                            <hr style={{
                                width: "100%",
                                border: "0.5px solid gray"
                            }} />
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                fontSize: "12px",
                                width: "100%"
                            }}>
                                <span>Status :</span> <span style={{
                                    color: "green"
                                }}>Active</span>
                            </div>

                            <hr style={{
                                width: "100%",
                                border: "0.5px solid gray"
                            }} />
                        </Card>
                        <Card style={{
                            width: "200px",
                            height: "120px",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: "10px",
                            padding: "10px",
                            boxShadow: "0 0 5px #ccc",
                            borderRadius: "10px"
                        }}>
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                fontSize: "12px",
                                width: "100%"
                            }}>
                                <span>Sensor Name: </span> <span>MQ2 Sensor</span>
                            </div>
                            <hr style={{
                                width: "100%",
                                border: "0.5px solid gray"
                            }} />
                            <div style={{
                                display: "grid",
                                gridTemplateColumns: "1fr 1fr",
                                fontSize: "12px",
                                width: "100%"
                            }}>
                                <span>Status :</span> <span style={{
                                    color: "green"
                                }}>Active</span>
                            </div>
                            <hr style={{
                                width: "100%",
                                border: "0.5px solid gray"
                            }} />
                        </Card> */}
                    </div>
                </div>
            </Card>
            {/* <div style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "column",
                alignItems: "center",
                margin: "20px 0"
            }}>
                <Card id="circuitry-workspace" sx={{
                    width: "100%",
                    minHeight: "70px",
                    margin: "10px",
                    display: "flex",
                    // flexDirection: "column",
                    alignItems: "center",
                    // justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "5px",
                    transition: "all 2s",
                }} onClick={() => {
                    navigate(routes.DASHBOARD.PROJECT.CIRCUITRY_WORKSPACE(project.project._id))
                }}>
                    <img src="https://cdn.dribbble.com/users/45697/screenshots/4804383/24fps.gif" alt="" style={{
                        width: "100px",
                        height: "70px",
                    }} />
                    <div style={{
                        width: "100%",
                        paddingLeft: "20px",
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <h6>

                            Circuit Design
                        </h6>
                        <span style={{
                            margin: "10px 30px",
                            fontSize: "12px",
                        }}>
                            {formatDistance(new Date(parseInt(project.circuit.lastUpdatedAt)), new Date(), { addSuffix: true })}
                        </span>
                    </div>
                    <Button color='secondary' size='small' variant='contained' sx={{
                        // padding: "5px",
                        marginRight: "20px",
                        minWidth: "80px",
                        fontSize: "12px",
                        textTransform: "none",
                        borderRadius: "10px"

                    }}>Edit</Button>
                </Card>
                <Card id="monitoring-workspace" sx={{
                    width: "100%",
                    minHeight: "70px",
                    margin: "10px",
                    display: "flex",
                    // flexDirection: "column", 
                    alignItems: "center",
                    // justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "5px",
                    transition: "all 2s",
                }} onClick={() => {
                }}>
                    <img src="https://i.pinimg.com/originals/d7/57/3b/d7573b3e236d935c3f87f3d5668a4695.gif" alt="" style={{
                        width: "100px",
                        height: "70px",
                    }} />
                    <div style={{
                        width: "100%",
                        paddingLeft: "20px",
                        display: "flex",
                        alignItems: "center",
                    }}>
                        <h6>

                            Monitor Design
                        </h6>
                        <span style={{
                            margin: "10px 30px",
                            fontSize: "12px",
                        }}>
                            updated {formatDistance(new Date(parseInt(project.monitor.lastUpdatedAt)), new Date(), { addSuffix: true })}
                        </span>
                    </div>
                    <Button color='secondary' size='small' variant='contained' sx={{
                        // padding: "5px",
                        marginRight: "20px",
                        minWidth: "80px",
                        fontSize: "12px",
                        textTransform: "none",
                        borderRadius: "10px"
                    }}>Edit</Button>
                </Card>
            </div> */}
        </div>
    )
}

export default ProjectDescription