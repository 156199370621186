import { CardMedia } from '@mui/material'
import React from 'react'
import { motion } from 'framer-motion'
import { framerAnimation } from '../../theme/animation/MotionConfigs'
type Props = {}

function SiteLoader({ }: Props) {
    return (
        <motion.div
            initial={framerAnimation.left2right.initial}
            animate={framerAnimation.left2right.animate}
            exit={framerAnimation.left2right.exit}
            transition={framerAnimation.left2right.transition}
        >
            <div className="container">
                <div className="loader">
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                    <div className="loaderDot"></div>
                </div>
            </div>
        </motion.div>
    )
}

export default SiteLoader