import { useDrag } from '@use-gesture/react';
import { animated, useSpring } from '@react-spring/web';
import { Button, Card, MenuItem, Select, TextField } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { pink } from '@mui/material/colors';
import Switch from '@mui/material/Switch';
import { useContext, useEffect, useState } from 'react';
import { ContentDialogBox } from '../../../../../components/dialog_box';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CommonColorsSelectionComponent, { getVariableValueFromVariableName, UpdateValueFromVariableName } from './Common';
import { ProjectDataType, WidgetDataType } from '../../../../../types/Dashboard';
import { SettingContext } from '../../../../../context/SettingsContext';

const TextInputComponent = ({ deleteWidget, widget, colorValue, setWidgets, project, previewMode = false }: {
    widget: WidgetDataType,
    deleteWidget: () => void,
    colorValue?: string,
    widgets: WidgetDataType[],
    setWidgets: React.Dispatch<React.SetStateAction<WidgetDataType[]>>,
    project: ProjectDataType,
    previewMode: boolean
}) => {
    const [isEditing, setIsEditing] = useState(false);
    const {settings, handleGlobalLoading}=useContext(SettingContext)
    const [title, setTitle] = useState(widget.widgetTitleText);
    const [currentVariable, setCurrentVariable] = useState(widget.linkedVariable);
    const [textValue, setTextValue] = useState(getVariableValueFromVariableName(currentVariable, project));
    const [color, setColor] = useState<"primary" | "secondary" | "error" | "info" | "success" | "warning" | "default" | string>(widget.widgetColor as string);
    useEffect(() => {
        setTextValue(getVariableValueFromVariableName(currentVariable, project))
    }, [currentVariable])
    useEffect(() => {
        setWidgets((preData) => {
            const newData = preData.map((widget_: WidgetDataType) => {
                if (widget_.widgetId === widget.widgetId) {
                    return {
                        ...widget_,
                        widgetTitleText: title,
                        linkedVariable: currentVariable,
                        widgetColor: color
                    }
                } else {
                    return widget_
                }
            })
            return newData
        })
    }, [color, currentVariable, title])
    async function syncChangesToVariable(textValue:string) {
        console.log(currentVariable,textValue);
        
        if(!currentVariable) return
            handleGlobalLoading(true)
            await UpdateValueFromVariableName(project.project._id, currentVariable, textValue)
            handleGlobalLoading(false)
    }
    return (
        <>
            {/* <animated.div {...bindLogo()} style={{
                x: position.x,
                y: position.y,
                maxWidth: "300px",
                touchAction: 'none',
                position: "relative"
            }} > */}
                <Card sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                    borderRadius: "10px",
                    // minWidth: "300px",
                    position: "relative",
                    margin: "10px",
                    height: "94%"
                }}>
                    <div style={{
                        position: "relative",
                        zIndex: 9,
                        width: "100%"
                    }}>
                        <div style={{
                            padding: "10px",
                        }}>
                            <p style={{
                                width: "100%",
                                fontSize: "12px",
                            }}>{title}</p>
                        </div>
                        <DeleteIcon sx={{
                            position: "absolute",
                            top: 5,
                            right: 30,
                            fontSize: "15px",
                            transition: "all 0.5s",
                            ":hover": {
                                scale: "1.5",
                            }
                        }} color='error' onClick={() => {
                            deleteWidget();
                        }} />
                        <EditIcon sx={{
                            position: "absolute",
                            top: 5,
                            right: 10,
                            fontSize: "15px",
                            transition: "all 0.5s",
                            ":hover": {
                                scale: "1.5",
                            }
                        }} color='disabled' onClick={() => {
                            setIsEditing(true);
                        }} />
                    </div>

                    <div style={{
                        padding: "10px",
                        width: "100%"
                    }}>
                        <TextField
                            multiline
                            fullWidth
                            maxRows={3}
                            label="Enter Text"
                            placeholder='Enter Text'
                            variant="outlined"
                            sx={{
                                width: "100%",
                                margin: "10px 0px"
                            }}
                            value={textValue}
                            onChange={(e) => {
                                setTextValue(e.target.value)
                            }}
                        />
                        <div style={{
                            display: "flex",
                            justifyContent:"end"
                        }}>

                        <Button size='small' variant="contained" onClick={()=>{
                            syncChangesToVariable(textValue)
                        }} sx={{
                            textTransform: "none",
                        }}>
                            Change
                        </Button>
                            </div>
                    </div>
                </Card>
            {/* </animated.div> */}
            <ContentDialogBox
                isOpen={isEditing}
                onClose={() => {
                    setIsEditing(false);
                }}
                title='Text Input Edit'
                maxWidth={"xs"}
                content={
                    <div>
                        <div style={{
                            padding: "10px",
                        }}>
                            <h6 style={{
                                textAlign: "center",
                            }}>Select Default Color for Widget</h6>
                            <div style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}>
                                <CommonColorsSelectionComponent color={color} setColor={setColor} />
                            </div>

                            <TextField size='small' fullWidth label="Title" value={title} onChange={(e) => {
                                setTitle(e.target.value)
                            }} style={{
                                margin: "10px 0px",
                            }} />
                            <Select size='small' label="Linked Variable" fullWidth labelId="demo-simple-select-label" id="demo-simple-select" value={currentVariable} onChange={(e) => {
                                setCurrentVariable(e.target.value)
                            }}>
                                {project.project.variables.map((variable) => {
                                    return <MenuItem value={variable.name}>{variable.name}</MenuItem>
                                })}
                            </Select>
                        </div>
                    </div>}
            />
        </>
    )
}
export default TextInputComponent