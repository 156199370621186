import React from 'react';
import { Dispatch, createContext, useEffect, useReducer } from 'react';

export type ConnectionDataType = {
    wifi: {
        isConnected: boolean;
        connectedModuleIP: string;
        moduleName: string;
    };
    software: {
        softwareIP: string;
        isConnected: boolean;
    };
    local: {
        isConnected: boolean;
        localIP: string;
        localNetworkName: string;
        type: string;
        isWiFiEnabled: boolean
    },
    default: {
        connectionType: "wifi",
        command: string
    }
};

export async function getLocalConnectionData(): Promise<ConnectionDataType | null> {
    const connection = await localStorage.getItem('connection');
    if (connection) {
        return JSON.parse(connection) as ConnectionDataType;
    } else {
        return null;
    }
}

export async function setLocalConnectionData(
    connection: ConnectionDataType,
): Promise<void> {
    await localStorage.setItem('connection', JSON.stringify(connection));
}

export async function removeLocalConnectionData(): Promise<void> {
    await localStorage.removeItem('connection');
}

type connectionActionType = {
    type: 'setWiFi' | 'setBluetooth' | 'setSoftware' | 'setLocal' | "setDefault";
    value: {
        data:
        | ConnectionDataType['wifi']
        | ConnectionDataType['software']
        | ConnectionDataType['local']
        | ConnectionDataType['default']
    };
};

const initialState: ConnectionDataType = {
    wifi: {
        isConnected: false,
        connectedModuleIP: '',
        moduleName: '',
    },
    software: {
        softwareIP: '',
        isConnected: false,
    },
    local: {
        isConnected: false,
        localIP: '',
        localNetworkName: '',
        type: '',
        isWiFiEnabled: false
    },
    default: {
        connectionType: 'wifi',
        command: 'none'
    }
};
function userReducer(
    state: ConnectionDataType,
    action: connectionActionType,
): ConnectionDataType {
    switch (action.type) {
        case 'setWiFi':
            setLocalConnectionData({
                ...state,
                wifi: action.value.data as ConnectionDataType['wifi'],
            });
            return {
                ...state,
                wifi: action.value.data as ConnectionDataType['wifi'],
            };
        case 'setSoftware':
            setLocalConnectionData({
                ...state,
                software: action.value.data as ConnectionDataType['software'],
            });
            return {
                ...state,
                software: action.value.data as ConnectionDataType['software'],
            };
        case 'setLocal':
            setLocalConnectionData({
                ...state,
                local: action.value.data as ConnectionDataType['local'],
            });
            return {
                ...state,
                local: action.value.data as ConnectionDataType['local'],
            };
        case 'setDefault':
            setLocalConnectionData({
                ...state,
                default: action.value.data as ConnectionDataType['default'],
            });
            return {
                ...state,
                default: action.value.data as ConnectionDataType['default'],
            };
        default:
            return state;
    }
}

export const ConnectionContext = createContext({
    connection: initialState,
    modifyConnection: (() => { }) as Dispatch<connectionActionType>,
    checkLocalConnectionData: (() => { }) as () => void,
});

export default function ConnectionProvider(props: { children: React.ReactNode }) {
    const [connection, modifyConnection] = useReducer(userReducer, initialState);
    async function checkLocalConnectionData() {
        const connection = await getLocalConnectionData();
        console.log('connection', connection);
    }
    useEffect(() => {
        checkLocalConnectionData();
    }, []);
    return (
        <ConnectionContext.Provider
            value={{
                connection,
                modifyConnection,
                checkLocalConnectionData,
            }}>
            {props.children}
        </ConnectionContext.Provider>
    );
}



export async function handleMachineCall(connection: ConnectionDataType, endPoint: string) {
    console.log(endPoint, " is calling");

    if (connection.default.connectionType == "wifi") {
        if (connection.wifi.isConnected) {
            await call_machine_function_on_callback(connection.wifi.connectedModuleIP, endPoint)
        } else {

        }
    }
}

export async function call_machine_function_on_callback(ipAddress: string, endPoint: string) {
    try {
        await fetch(`http://${ipAddress}/${endPoint}`);
        return true
    } catch (error) {
        console.log(error);
        return false
    }
}

