import React, { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import { SettingContext } from '../../../context/SettingsContext'
import DashboardLayout from '../DashboardConfigs/DashboardLayout'
import SampleProjectListComponent from './SampleProjectListComponent'

type Props = {}

export default function SampleProjectsPage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)

    return (
        <div>
            <DashboardLayout title='Blueprints'>
                <SampleProjectListComponent />
            </DashboardLayout>
        </div>
    )
}