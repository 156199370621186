import { Card } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Chart from 'react-apexcharts';
import GraphComponent from '../../../components/graph';
import { ProjectDataType } from '../../../types/Dashboard';
import { BackendConfigs } from '../../../config.environment';

type Props = {
    project: ProjectDataType
}


async function GetSensorsDataByProjectIdAPI(projectId: string) {
    const response = await fetch(`${BackendConfigs.url}/GetSensorsDataByProjectIdAPI`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            projectId: projectId,
        })
    })
    const result = await response.json()
    return result.data
}



function ProjectStatistics({
    project
}: Props) {
    const [labels, setLabels] = useState<string[]>([]);
    const [data, setData] = useState<number[]>([]);
    const [sensorsData, setSensorsData] = useState<{
        name: string,
        variableName: string,
        variableValue: string,
        createdAt: string,
        projectName: string
    }[]>([]);

    async function getChartData() {

        try {
            const hits = await GetSensorsDataByProjectIdAPI(project.project._id) as {
                name: string,
                variableName: string,
                variableValue: string,
                createdAt: string,
            }[];
            if (!!hits) {
                const seconds = hits.map((hit) => {
                    const time = (new Date(parseInt(hit.createdAt))).toLocaleTimeString()
                    return time.slice(0, -6) + " " + time.slice(-2)
                })
                // console.log(seconds);
                let values = {} as {
                    [key: string]: number
                }
                seconds.forEach(element => {
                    values[element] = element in values ? values[element] + 1 : 1
                });
                setLabels(Object.keys(values).reverse())
                setData(Object.values(values).reverse())
            }
        } catch (error) {

        }
    }




    useEffect(() => {
        getChartData();
    }, [])




    return (
        <div style={{
            minHeight: "20vh",
            display: 'flex',
            margin: "0 10px",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column"
        }}>
            <p>
                This Project Statistics
            </p>


            <h3>All Sensors Hits</h3>
            <p style={{
                fontSize: "14px",
            }}>
                data is till {
                    new Date(parseInt(project.lastInteraction)).toLocaleString()
                }
            </p>
            <div id='graph-of-last-day' style={{
                backdropFilter: "blur(5px)",
                width: "100%",
            }}>
                <GraphComponent
                    labels={labels}
                    data={data}
                    yLabel="Hits"
                    xLabel="Time"
                    height={400}
                    valueType="number"
                    graphType="line"
                />
            </div>
        </div>
    )
}

export default ProjectStatistics