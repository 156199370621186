import { ToolboxDefinition } from "react-blockly"
import { BlockColors } from "./color-mapping"
import { ToolboxCategory } from "blockly"

const MicroPythonToolBox = {
    "kind": "categoryToolbox",
    "contents": [
        {
            "kind": "CATEGORY",
            "name": "Traditional",
            "colour": BlockColors['Section Color'],

        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "loop_esp"
                },
                {
                    "kind": "BLOCK",
                    "type": "controls_repeat_ext"
                },
                {
                    "kind": "BLOCK",
                    "type": "controls_whileUntil"
                },
                {
                    "kind": "BLOCK",
                    "type": "controls_for"
                },
                {
                    "kind": "BLOCK",
                    "type": "controls_forEach"
                },
                {
                    "kind": "BLOCK",
                    "type": "controls_flow_statements"
                }
            ],
            "name": "Loop",
            "colour": BlockColors['Loop']
        },
        {
            "kind": "CATEGORY",
            "custom": "VARIABLE",
            "name": "Variables",
            "colour": BlockColors['Variables']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "controls_if"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_compare"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_operation"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_negate"
                },
                {
                    "kind": "BLOCK",
                    "type": "logic_boolean"
                }
            ],
            "name": "Conditions",
            "colour": BlockColors['Conditions']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "pins"
                },
                {
                    "kind": "BLOCK",
                    "type": "analog_pins"
                },
                {
                    "kind": "BLOCK",
                    "type": "tf"
                },
                {
                    "kind": "BLOCK",
                    "type": "text-block"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_number"
                }
            ],
            "name": "Constants",
            "colour": BlockColors['Constants']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "procedures_callreturn"
                },
                {
                    "kind": "BLOCK",
                    "type": "call_function"
                },

            ],
            "name": "Functions",
            "colour": BlockColors['Functions']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "math_arithmetic"
                },
                {
                    "kind": "BLOCK",
                    "type": "abs"
                },
                {
                    "kind": "BLOCK",
                    "type": "map"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_random_int"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_constrain"
                },
                {
                    "kind": "BLOCK",
                    "type": "min"
                },
                {
                    "kind": "BLOCK",
                    "type": "max"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_single"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_round"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_trig"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_constant"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_number_property"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_modulo"
                },
                {
                    "kind": "BLOCK",
                    "type": "math_random_float"
                }
            ],

            "name": "Math",
            "colour": BlockColors['Math']
        },

        {
            "kind": "SEP",
        },
        {
            "kind": "SEP",
        },

        // {
        //     "kind": "CATEGORY",
        //     "name": "",
        //     "colour": BlockColors['Section Color']
        // },
        {
            "kind": "CATEGORY",
            "name": "Machine and Process",
            "colour": BlockColors['Section Color']

        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "print"
                },
                {
                    "kind": "BLOCK",
                    "type": "digital_write"
                },
                {
                    "kind": "BLOCK",
                    "type": "analog_write"
                },
                {
                    "kind": "BLOCK",
                    "type": "digital_pin_pin"
                },
                {
                    "kind": "BLOCK",
                    "type": "analog_input_pin"
                },
            ],
            "name": "Machine",
            "colour": BlockColors['Machine']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "delay"
                },
            ],
            "name": "Time",
            "colour": BlockColors['Time']
        },
        {
            "kind": "SEP",
        },
        {
            "kind": "SEP",
        },

        {
            "kind": "CATEGORY",
            "name": "Network",
            "colour": BlockColors['Section Color']
        },

        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "initialize_wifi" // initialization code
                },
                {
                    "kind": "BLOCK",
                    "type": "set_wifi_credentials" // block that set values of ssid and password
                },
                {
                    "kind": "BLOCK",
                    "type": "connect_to_wifi" // initialization function to connect wifi
                },
                {
                    "kind": "BLOCK",
                    "type": "is_wifi_connected" // return boolean value
                },
                {
                    "kind": "BLOCK",
                    "type": "start_server" // initialization code for 
                },
                {
                    "kind": "BLOCK",
                    "type": "get_ip_address" //return value
                },
                {
                    "kind": "BLOCK",
                    "type": "handle_request" //block that inset
                },
                {
                    "kind": "BLOCK",
                    "type": "is_request_equals_to" // match request with input 
                },
            ],
            "name": "WiFi",
            "colour": BlockColors['WiFi']
        },

        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "initialize_bt"
                },
                {
                    "kind": "BLOCK",
                    "type": "bt_available"
                },
                {
                    "kind": "BLOCK",
                    "type": "bt_read"
                },
                {
                    "kind": "BLOCK",
                    "type": "bt_read_str"
                },
                {
                    "kind": "BLOCK",
                    "type": "bt_print"
                },
                {
                    "kind": "BLOCK",
                    "type": "end_of_print"
                },
                {
                    "kind": "BLOCK",
                    "type": "initialize_bt"
                },
            ],
            "name": "Bluetooth",
            "colour": BlockColors['Bluetooth']
        },
        {
            "kind": "SEP",
        },

        {
            "kind": "SEP",
        },

        {
            "kind": "CATEGORY",
            "name": "IOT",
            "colour": BlockColors['Section Color']

        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "initialize_IOT"
                },
                {
                    "kind": "BLOCK",
                    "type": "send_data_iot"
                },
                {
                    "kind": "BLOCK",
                    "type": "get_data_iot"
                },
            ],
            "name": "IOT",
            "colour": BlockColors['IOT']
        },

        {
            "kind": "SEP",
        },

        {
            "kind": "SEP",
        },

        {
            "kind": "CATEGORY",
            "name": "Input Sensors",
            "colour": BlockColors['Section Color']

        },

        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="ir_sensor_input">
                                    <value name="pin">
                                        <block type="pins">
                                            <field name="pins">19</field>                
                                        </block>               
                                    </value>
                                </block>`,
                    "type": "ir_sensor_input"
                },
            ],
            "name": "IR Sensor",
            "colour": BlockColors['IR Sensor']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="light_sensor_input">
                    <value name="pin">
                        <block type="pins">
                            <field name="pins">18</field>                
                        </block>               
                    </value>
                </block>`,
                    "type": "light_sensor_input"
                },
            ],
            "name": "LDR Sensor",
            "colour": BlockColors['LDR Sensor']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="touch_sensor_input">
                    <value name="pin">
                        <block type="pins">
                            <field name="pins">15</field>                
                        </block>               
                    </value>
                </block>`,
                    "type": "touch_sensor_input"
                },
            ],
            "name": "Touch Sensor",
            "colour": BlockColors['Touch Sensor']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="define_ultrasonic_pins">
                    <value name="trig">
                        <block type="pins">
                            <field name="pins">4</field>                
                        </block>               
                    </value>
                    <value name="echo">
                        <block type="pins">
                            <field name="pins">2</field>                
                        </block>               
                    </value>
                </block>`,

                    "type": "define_ultrasonic_pins"
                },

                {
                    "kind": "BLOCK",
                    "type": "dist_cm"
                },
            ],
            "name": "Ultra Sonic Sensor",
            "colour": BlockColors['Ultra Sonic Sensor']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="define_dht_pin">
                                    <value name="pin">
                                        <block type="pins">
                                            <field name="pins">5</field>                
                                        </block>               
                                    </value>
                                </block>`,

                    "type": "define_dht_pin"
                },
                {
                    "kind": "BLOCK",
                    "type": "read_humidity"
                },
                {
                    "kind": "BLOCK",
                    "type": "read_temp_c"
                },
                {
                    "kind": "BLOCK",
                    "type": "read_temp_f"
                },
            ],
            "name": "DHT11 Sensor",
            "colour": BlockColors['DHT11 Sensor']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"moisture_sensor_input\">\n                <value name=\"pin\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">1</field>\n                    </block>\n                </value>\n                <value name=\"value\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "moisture_sensor_input"
                },
            ],
            "name": "Moisture Sensor",
            "colour": BlockColors['Buzzer']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"mq2_digital_input\">\n                <value name=\"pin\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">1</field>\n                    </block>\n                </value>\n                <value name=\"value\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "mq2_digital_input"
                },
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"mq2_analog_input\">\n                <value name=\"pin\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">35</field>\n                    </block>\n                </value>\n                <value name=\"value\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "mq2_analog_input"
                },
                {
                    "kind": "BLOCK",
                    "type": "mq2_lpg_percentage"
                },
                {
                    "kind": "BLOCK",
                    "type": "mq2_smoke_percentage"
                },
                {
                    "kind": "BLOCK",
                    "type": "mq2_hydrogen_percentage"
                },
                {
                    "kind": "BLOCK",
                    "type": "mq2_methane_percentage"
                },
            ],
            "name": "MQ2 Sensor",
            "colour": BlockColors['Functions']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "joystick_pins"
                },
                {
                    "kind": "BLOCK",
                    "type": "jysX"
                },
                {
                    "kind": "BLOCK",
                    "type": "jysY"
                },
            ],
            "name": "Joystick",
            "colour": BlockColors['8x8  Matrix']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "initialize_keypad"
                },
                {
                    "kind": "BLOCK",
                    "type": "keypad_col_pins"
                },
                {
                    "kind": "BLOCK",
                    "type": "keypad_row_pins"
                },
                {
                    "kind": "BLOCK",
                    "type": "readkey"
                },
                {
                    "kind": "BLOCK",
                    "type": "key_press_detected_in"
                },
            ],
            "name": "Keypad",
            "colour": BlockColors['Keypad']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "init_colour_sensor"
                },
                {
                    "kind": "BLOCK",
                    "type": "activate_red_mode"
                },
                {
                    "kind": "BLOCK",
                    "type": "activate_green_mode"
                },
                {
                    "kind": "BLOCK",
                    "type": "activate_blue_mode"
                },
                {
                    "kind": "BLOCK",
                    "type": "read_colour_intensity"
                },
            ],
            "name": "Color Sensor",
            "colour": BlockColors['IOT']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "init_rfid"
                },
                {
                    "kind": "BLOCK",
                    "type": "card_present"
                },
                {
                    "kind": "BLOCK",
                    "type": "read_rfid_card"
                },
                {
                    "kind": "BLOCK",
                    "type": "print_id"
                },
                {
                    "kind": "BLOCK",
                    "type": "id_number"
                },

            ],
            "name": "RFID Sensor",
            "colour": BlockColors['RFID Sensor']
        },





        {
            "kind": "SEP",
        },
        {
            "kind": "SEP",
        },

        {
            "kind": "CATEGORY",
            "name": "Output Sensors",
            "colour": BlockColors['Section Color']

        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"set_led\">\n                <value name=\"pin\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">2</field>\n                    </block>\n                </value>\n                <value name=\"value\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "set_led"
                },
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"traffic_light_upgraded\">\n                <value name=\"pin1\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">2</field>\n                    </block>\n                </value>\n                <value name=\"value1\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n                <value name=\"pin2\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">4</field>\n                    </block>\n                </value>\n                <value name=\"value2\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n                <value name=\"pin3\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">5</field>\n                    </block>\n                </value>\n                <value name=\"value3\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "traffic_light_upgraded"
                },
            ],
            "name": "LED",
            "colour": BlockColors['LED']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"set_buzzer\">\n                <value name=\"pin\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">27</field>\n                    </block>\n                </value>\n                <value name=\"value\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "set_buzzer"
                },
            ],
            "name": "Buzzer",
            "colour": BlockColors['Buzzer']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"set_motor\">\n                <value name=\"pin1\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">25</field>\n                    </block>\n                </value>\n                <value name=\"pin2\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">26</field>\n                    </block>\n                </value>\n                <value name=\"value1\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n                <value name=\"value2\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "set_motor"
                },
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"set_bo_motor\">\n                <value name=\"pin1\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">25</field>\n                    </block>\n                </value>\n                <value name=\"pin2\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">26</field>\n                    </block>\n                </value>\n                <value name=\"value1\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n                <value name=\"value2\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">0</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "set_bo_motor"
                },
                {
                    "kind": "BLOCK",
                    "blockxml": "<block type=\"pump_motor\">\n                <value name=\"pin1\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">25</field>\n                    </block>\n                </value>\n                <value name=\"pin2\">\n                    <block type=\"pins\">\n                        <field name=\"pins\">26</field>\n                    </block>\n                </value>\n                <value name=\"value1\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">1</field>\n                    </block>\n                </value>\n                <value name=\"value2\">\n                    <block type=\"tf\">\n                        <field name=\"tf\">1</field>\n                    </block>\n                </value>\n            </block>",

                    "type": "pump_motor"
                },
            ],
            "name": "Motor",
            "colour": BlockColors['Motor']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "init_i2c_lcd"
                },
                {
                    "kind": "BLOCK",
                    "type": "i2c_clear_lcd_display"
                },
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="i2c_lcd_print">
                    <value name="r">
                        <block type="math_number">
                            <field name="math_number">0</field>                
                        </block>               
                    </value>
                    <value name="c">
                        <block type="math_number">
                            <field name="math_number">0</field>                
                        </block>               
                    </value>
                </block>`,
                    "type": "i2c_lcd_print"
                },
            ],
            "name": "LCD",
            "colour": BlockColors['LCD']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="8-pin-seven-segment">
                                    <value name="pin1">
                                        <block type="pins"> 
                                            <field name="pins">13</field>
                                        </block>                
                                    </value>                              
                                    <value name="value1">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  


                                    <value name="pin2">
                                        <block type="pins"> 
                                            <field name="pins">12</field>
                                        </block>                
                                    </value>                              
                                    <value name="value2">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  


                                    <value name="pin3">
                                        <block type="pins"> 
                                            <field name="pins">14</field>
                                        </block>                
                                    </value>                              
                                    <value name="value3">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  


                                    <value name="pin4">
                                        <block type="pins"> 
                                            <field name="pins">27</field>
                                        </block>                
                                    </value>                              
                                    <value name="value4">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  


                                    <value name="pin5">
                                        <block type="pins"> 
                                            <field name="pins">26</field>
                                        </block>                
                                    </value>                              
                                    <value name="value5">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  


                                    <value name="pin6">
                                        <block type="pins"> 
                                            <field name="pins">25</field>
                                        </block>                
                                    </value>                              
                                    <value name="value6">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  


                                    <value name="pin7">
                                        <block type="pins"> 
                                            <field name="pins">33</field>
                                        </block>                
                                    </value>                              
                                    <value name="value7">                    
                                        <block type="tf">                        
                                            <field name="tf">1</field>                    
                                        </block>                
                                    </value>  
                                </block>                
`,

                    "type": "8-pin-seven-segment"
                },
                {
                    "kind": "BLOCK",
                    "type": "eight_segment_display_define"
                },
                {
                    "kind": "BLOCK",
                    "type": "show_on_7_segment_display"
                },
            ],
            "name": "7-Segment Display",
            "colour": BlockColors['Variables']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "blockxml": `<block type="servo">
                    <value name="pin">
                        <block type="pins">
                            <field name="pins">5</field>                
                        </block>               
                    </value>
                    <value name="degree">
                        <block type="math_number">
                            <field name="math_number">0</field>                
                        </block>               
                    </value>
                </block>`,
                    "type": "servo"
                },
            ],
            "name": "Servo",
            "colour": BlockColors['Servo']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "init_i2c_OLED"
                },
                {
                    "kind": "BLOCK",
                    "type": "i2c_OLED_print"
                },
            ],
            "name": "OLED",
            "colour": BlockColors['LCD']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "init_i2c_adxl345"
                },
                {
                    "kind": "BLOCK",
                    "type": "adxl345_result"
                },
            ],
            "name": "Accelerometer",
            "colour": BlockColors['Variables']
        },
        {
            "kind": "CATEGORY",
            "contents": [
                {
                    "kind": "BLOCK",
                    "type": "8x8_matrix"
                },
                {
                    "kind": "BLOCK",
                    "type": "print_on_matrix"
                },
            ],
            "name": "8x8  Matrix",
            "colour": BlockColors['8x8  Matrix']
        },

    ],
}



export default MicroPythonToolBox