import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext'
import DashboardLayout from '../DashboardConfigs/DashboardLayout'
import ProjectStatistics from './ProjectStatistics'
import ProjectDescription from './ProjectDescription'
import { Button, Card, Tooltip } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { routes } from '../../../routes/routes'
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import SettingsIcon from '@mui/icons-material/Settings';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import { BackendConfigs } from '../../../config.environment'
import { APIResponse } from '../../../types/Response'
import { toast } from 'react-toastify'
import { ContentDialogBox } from '../../../components/dialog_box'
import { ProjectDataType } from '../../../types/Dashboard'
import ProjectSettings from './ProjectSettings'
import MonitorIcon from '@mui/icons-material/Monitor';


type Props = {}

export default function ProjectPage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)
    const navigate = useNavigate()
    const { projectId } = useParams();
    const [projectData, setProjectData] = useState<ProjectDataType | null>(null)
    const [isSettingsOpen, setIsSettingsOpen] = useState(false)
    async function getProject() {
        console.log(projectId, user.userData);
        if (!!user.userData && !!projectId) {
            handleGlobalLoading(true, "Fetching Project")
            setProjectData(null)
            try {
                const response = await fetch(`${BackendConfigs.url}/GetProjectByProjectIdAndOwnerIdAPI`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        ownerId: user.userData?.id,
                        projectId: projectId,
                    })
                })
                if (response.status === 200) {
                    const result = await response.json() as APIResponse
                    // toast.success(result.message)
                    // console.log(result.data);
                    setProjectData(result.data)
                } else {
                    navigate(routes.DASHBOARD.ROOT)
                }
            } catch (error) {
                toast.error("Error Fetching Project")
                console.log(error);
            }
            handleGlobalLoading(false)
        }
    }

    useEffect(() => {
        getProject()
    }, [user, projectId])
    useEffect(() => {
        setTimeout(() => {
            // driverObj.drive();
        }, 1000)
    }, [])
    return (
        <div>
            <DashboardLayout title={""}>
                <div style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                }}>
                    <Tooltip title="Circuitry">
                        <Button onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.CIRCUITRY_WORKSPACE(projectData ? projectData.project._id : ""))

                        }}>
                            <img src="https://static-00.iconduck.com/assets.00/circuit-icon-2048x2048-p9bxoawz.png" style={{
                                maxWidth: "30px",
                            }} alt="" />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Monitoring">
                        <Button onClick={() => {
                            navigate(routes.DASHBOARD.PROJECT.MONITORING_WORKSPACE(projectData ? projectData.project._id : ""))

                        }} style={{
                            color: eduCOBOTColorScheme.black
                        }}>
                            <MonitorIcon />
                        </Button>
                    </Tooltip>
                    <Tooltip title="Project Settings">

                        <Button onClick={() => {
                            setIsSettingsOpen(true)
                        }} style={{
                            color: eduCOBOTColorScheme.black
                        }}>
                            <SettingsIcon />
                        </Button>
                    </Tooltip>
                </div>

                {!!projectData && <ProjectDescription project={projectData} />}
                {!!projectData && projectData.lastInteraction &&  <ProjectStatistics  project={projectData}/>}


                <ContentDialogBox
                    isOpen={isSettingsOpen}
                    onClose={() => {
                        setIsSettingsOpen(false)
                    }}
                    title={`Settings for ${projectData?.project.name}`}
                    content={
                        <>
                            {
                                projectData && <ProjectSettings setIsSettingsOpen={setIsSettingsOpen} refreshProject={getProject} projectData={projectData} />
                            }

                        </>
                    }
                />
            </DashboardLayout>
        </div>
    )
}