import React, { useEffect } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { routes } from './routes'
import Home from '../pages/Home/index'
import Dashboard from '../pages/Dashboard'
import DashboardProject from '../pages/Projects'
import DashboardPage from '../pages/Dashboard'
import WorkspacePage from '../pages/Dashboard/Projects'
import ProjectPage from '../pages/Dashboard/Project'
import CircuitryWorkspacePage from '../pages/Dashboard/Project/CircuitryWorkspace'
import MonitoringWorkspacePage from '../pages/Dashboard/Project/MonitoringWorkspace'
import SampleProjectsPage from '../pages/Dashboard/Blueprints'
import CircuitDesignerPage from '../pages/CircuitDesigner'
import SettingsPage from '../pages/Dashboard/Settings'
import MicroPythonEditorPage from '../pages/MicropythonEditor'
import SharedMonitorPage from '../pages/SharedMonitor'
import MonitoringWorkspaceForApplication from '../pages/Dashboard/Project/MonitoringWorkspaceForApplication'

function Router() {
    return (
        <Routes>
            <Route path={routes.ROOT} element={<Home />} />
            <Route path={routes.CIRCUIT_DESIGNER} element={<CircuitDesignerPage />} />




            <Route path={routes.DASHBOARD.ROOT} element={<DashboardPage />} />
            <Route path={routes.DASHBOARD.PROJECTS} element={<WorkspacePage />} />
            <Route path={routes.DASHBOARD.BLUEPRINTS} element={<SampleProjectsPage />} />
            <Route path={routes.DASHBOARD.SETTINGS} element={<SettingsPage />} />

            <Route path={routes.DASHBOARD.PROJECT.PROJECT_BY_ID} element={<ProjectPage />} />
            <Route path={routes.DASHBOARD.PROJECT.CIRCUITRY_WORKSPACE(":projectId")} element={<CircuitryWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.MONITORING_WORKSPACE(":projectId")} element={<MonitoringWorkspacePage />} />
            <Route path={routes.DASHBOARD.PROJECT.MONITORING_WORKSPACE_FOR_APP(":projectId")} element={<MonitoringWorkspaceForApplication />} />
            
            
            <Route path={routes.LOGIC_GATE_SIMULATOR} element={<OpenLogicGateSimulator/>} />
            <Route path={routes.MICROPYTHON_EDITOR} element={<MicroPythonEditorPage/>} />


            
            <Route path={routes.SHARED_MONITORING} element={<MonitoringWorkspaceForApplication/>} />





            <Route path={routes.NOTFOUND} element={<div>Not Found</div>} />
            <Route path={routes.WILDCARD} element={<Navigate to={routes.NOTFOUND} replace={true} />} />
        </Routes>
    )
}



function OpenLogicGateSimulator() {
    useEffect(() => {
        window.location.href = routes.LOGIC_GATE_SIMULATOR
    },[])
    return (
        <></>
    )
}

export default Router