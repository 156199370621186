import React from 'react'
import DescriptionEditing from './DescriptionEditing'
import AccessEditing from './AccessEditing'
import { ProjectDataType } from '../../../../types/Dashboard'
import DeleteProject from './DeleteProject'

type Props = {
    projectData: ProjectDataType,
    setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    refreshProject: () => void
}

function ProjectSettings({
    projectData,
    setIsSettingsOpen,
    refreshProject
}: Props) {
    return (
        <div>
            <AccessEditing project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} />
            <DescriptionEditing project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} />
            <DeleteProject project={projectData.project} setIsSettingsOpen={setIsSettingsOpen} refreshProject={refreshProject} />
        </div>
    )
}

export default ProjectSettings