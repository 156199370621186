import React, { useContext, useState } from 'react'
import { ProjectDataType } from '../../../../types/Dashboard'
import { Switch, Typography } from '@mui/material'
import { UserContext } from '../../../../context/UserContext'
import { eduCOBOTColorScheme, SettingContext } from '../../../../context/SettingsContext'
import { BackendConfigs } from '../../../../config.environment'
import { APIResponse } from '../../../../types/Response'
import { toast } from 'react-toastify'

type Props = {
    project: ProjectDataType['project'],
    setIsSettingsOpen: React.Dispatch<React.SetStateAction<boolean>>,
    refreshProject: () => void
}

function AccessEditing({ project, setIsSettingsOpen, refreshProject }: Props) {
    const { user } = useContext(UserContext)
    const { settings, handleGlobalLoading } = useContext(SettingContext)

    const [currentAccess, setCurrentAccess] = useState<boolean>(project.isPublic)
    async function updateAccess(access: boolean) {
        handleGlobalLoading(true, "updating access")
        try {
            const response = await fetch(`${BackendConfigs.url}/ChangeAccessAPI`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ownerId: user.userData?.id,
                    projectId: project._id,
                    isPublic: access
                })
            })
            if (response.status === 200) {
                const result = await response.json() as APIResponse
                if (result.success) {
                    toast.success(result.message)
                    refreshProject()
                } else {
                    toast.error(result.message)
                }
            } else {
                toast.error("Details can't be updated1")
            }

        } catch (error) {
            console.log(error);
        }
        handleGlobalLoading(false, "updating access")

    }
    return (
        <div style={{
            width: "95%",
            margin: "auto",
        }}>
            <Typography sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end"
            }}>
                <h6>Private</h6>
                <Switch defaultChecked={false} onChange={() => { setCurrentAccess(!currentAccess); updateAccess(!currentAccess) }} checked={currentAccess} />
                <h6>Public</h6>
            </Typography>
            <p style={{ textAlign: "right", fontSize: "12px", color: currentAccess ? eduCOBOTColorScheme.red : eduCOBOTColorScheme.green }}>
                {
                    currentAccess ? <>
                        Anyone with monitor link of this project will have access to view it
                    </> : <>
                        This project is private to you only.
                    </>
                }
            </p>
        </div>
    )
}

export default AccessEditing