import { Button, Container, Stack, ToggleButton, Tooltip, Typography } from '@mui/material'
import React, { useContext } from 'react'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { UserContext } from '../../../context/UserContext';
import { eduCOBOTColorScheme, SettingContext } from '../../../context/SettingsContext';
import DehazeIcon from '@mui/icons-material/Dehaze';
import { Link } from 'react-router-dom';
import { routes } from '../../../routes/routes';
import HomeIcon from '@mui/icons-material/Home';
type Props = {
    title: string,
    toggleNavBarOpen?: () => void
}

function DashboardTopbar({ title = "eduCOBOT IOT", toggleNavBarOpen }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)
    return (
        <div style={{
            maxHeight: "10vh"
        }}>
            <Container sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px",
                position: "relative",
                top: 0,
                zIndex: "1000",
            }}>
                <h3 style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: eduCOBOTColorScheme.gray
                }}>
                    {
                        settings.screen === "mobile" &&
                        <Typography component={'div'} style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "20px"
                        }} onClick={() => {
                            toggleNavBarOpen && toggleNavBarOpen()
                        }}>
                            <DehazeIcon />
                        </Typography>
                    }
                    {title}
                </h3>
                <div>
                    <Stack direction="row" spacing={5} sx={{
                        alignItems: "center"
                    }}>
                        <div>
                            <Tooltip title="go to https://educobot.com">
                                <Button size='small' color="primary" component={Link} to={"https://educobot.com/auth/login"} style={{fontSize: "10px",color:eduCOBOTColorScheme.black}}><HomeIcon style={{
                                    // fontSize: "10px"
                                }}/></Button>
                            </Tooltip>
                        </div>
                        {/* <Tooltip title="Change Theme">

                            <ToggleButton
                                style={{ borderRadius: "50px", border: "none" }}
                                value="check"
                                onChange={toggleTheme}
                            >
                                {settings.themeMode === "dark" && <LightModeIcon color={"primary"} />}
                                {settings.themeMode === "light" && <DarkModeIcon color={"primary"} />}
                            </ToggleButton>
                        </Tooltip> */}
                    </Stack>
                </div>
            </Container>
        </div>
    )
}

export default DashboardTopbar