import React from 'react'
import { routes } from '../../../routes/routes'
import DashboardIcon from '@mui/icons-material/Dashboard';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import ConstructionIcon from '@mui/icons-material/Construction';
import { Blocks, Container } from 'lucide-react';
import SettingsIcon from '@mui/icons-material/Settings';

function DashboardNavbarIcon(icon: string, size: string = "20px") {
    switch (icon) {
        case routes.DASHBOARD.ROOT:
            return <DashboardIcon style={{
                fontSize: size,
            }} />
        case routes.DASHBOARD.PROJECTS:
            return <DeveloperBoardIcon style={{
                fontSize: size,
            }} />
        case routes.CIRCUIT_DESIGNER:
            return <img src='https://innovator-resourses.s3.ap-south-1.amazonaws.com/new_icons/Home_Outline_1.png' style={{
                width: size,
                filter: "grayscale(50%)",
            }} />
        case routes.LOGIC_GATE_SIMULATOR:
            return <img src='https://cdn-icons-png.flaticon.com/512/3512/3512522.png' style={{
                width: size,
                filter: "grayscale(50%)",
            }} />
            case routes.DASHBOARD.SETTINGS:
                return <SettingsIcon style={{
                    fontSize: size,
                }} />
        default:
            return <ConstructionIcon style={{
                fontSize: size,
            }} />
    }
}

export default DashboardNavbarIcon

export const DashboardNavConfig = {
    navigation: [
        {
            title: "Dashboard",
            link: routes.DASHBOARD.ROOT
        },
        {
            title: "Projects",
            link: routes.DASHBOARD.PROJECTS
        },
        {
            title: "Blueprints",
            link: routes.DASHBOARD.BLUEPRINTS
        },
        // {
        //     title: "Circuit Designer",
        //     link: routes.CIRCUIT_DESIGNER
        // },
        // {
        //     title: "Logic Gate Simulator",
        //     link: routes.LOGIC_GATE_SIMULATOR
        // },
        // {
        //     title: "MicroPython Editor",
        //     link: routes.MICROPYTHON_EDITOR
        // },
        {
            title: "Settings",
            link: routes.DASHBOARD.SETTINGS
        },
    ]
}