import React, { useContext } from 'react'
import { UserContext } from '../../../context/UserContext'
import { SettingContext } from '../../../context/SettingsContext'
import DashboardLayout from '../DashboardConfigs/DashboardLayout'
import CreateProjectComponent from './CreateProjectComponent'
import ProjectListComponent from './ProjectListComponent'

type Props = {}

export default function ProjectsPage({ }: Props) {
    const { user, changeUser } = useContext(UserContext)
    const { settings, changeSettings, changeThemeScheme, toggleTheme } = useContext(SettingContext)
    const [isDataChanged, setIsDataChanged] = React.useState<any>(false)
    return (
        <div>
            <DashboardLayout title='Projects'>
                <CreateProjectComponent setIsDataChanged={setIsDataChanged} />
                <ProjectListComponent setIsDataChanged={setIsDataChanged} key={isDataChanged} />
            </DashboardLayout>
        </div>
    )
}