import React from 'react'
import APIKeySettings from './APIKeySettings'

type Props = {}

function Settings({ }: Props) {
    return (
        <div style={{
            width: "100%",
        }}>
            <APIKeySettings />
        </div>
    )
}

export default Settings