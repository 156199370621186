import React from 'react'
import CircuitDesignerComponent from '../../components/circuit-designer'

type Props = {}

function CircuitDesignerPage({}: Props) {
  return (
    <div>
      <CircuitDesignerComponent/>
    </div>
  )
}

export default CircuitDesignerPage