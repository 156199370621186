const PORT = process.env.PORT || 3000;



const NODE_ENVIRONMENT = process.env.NODE_ENV || "production"


const DEPLOYMENT_TYPE = NODE_ENVIRONMENT === "development" ? "development" : "production"
console.log(process.env.DEPLOYMENT_TYPE);

const BackendURL = DEPLOYMENT_TYPE === "production" ? "https://backend.server.iot.educobot.com" : process.env.DEPLOYMENT_TYPE === "testing" ? "https://backend.server.iot.educobot.com" : "http://127.0.0.1:9000"
const FrontendURL = DEPLOYMENT_TYPE === "production" ? "https://iot.educobot.com" : process.env.DEPLOYMENT_TYPE === "testing" ? "https://iot.educobot.com" : "http://localhost:3010"


export const MainConfigs = {
    NODE_ENVIRONMENT,
    DEPLOYMENT_TYPE,
}



export const BackendConfigs = {
    url: BackendURL,
    apis: {
        auth: {
            
        },
        users: {
            
        }
    }
};

export const FrontendConfigs = {
    url: FrontendURL,
};


export const SiblingConfigs = {
    sibling1: {
        url: ``,
        paths: {
            
        }
    }
}

export const ChildrenConfigs = {
    child1: {
        url: ``,
        paths: {

        }
    }
}

export const ParentConfigs = {
    url: ``,
    paths: {

    }
}



export const ServicesConfigs = {
    payment: {

    },
    storage: {

    },
    syncfusion:{
        KEY:"Ngo9BigBOggjHTQxAR8/V1NCaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXhfdHRcQ2ZYWUZzX0c="
    }
}